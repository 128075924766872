import axios from "axios";
import config from "@/config/appConfig";
import store from "@/redux/store";

const interviewInstaance = axios.create({
  baseURL: config.interviewBaseUrl
});

interviewInstaance.interceptors.request.use((instanceConfig) => {
  const { token } = store.getState().user;
  if (token) {
    if (!instanceConfig.headers.Authorization)
      // eslint-disable-next-line no-param-reassign
      instanceConfig.headers.Authorization = token;
  }
  return instanceConfig;
});
export default interviewInstaance;
