import store from "@/redux/store";
import { addSpecificConfig } from "@/redux/actions/specificConfigActions";
import {
  mainAxiosInstance,
  socialAxiosInstance
} from "./networkAdapters/axiosInstance";
import config from "../config/appConfig";

export function sendAppLinkApi(number, countryCode) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post("/utilities/sendAppLink", {
        number,
        countryCode
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in sendAppLink", e.response);
        reject(e);
      });
  });
}
export function fetchConfigApi() {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/config/config`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchConfigApi", e.response);
        reject(e);
      });
  });
}
export function fetchStaticConfigApi() {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/config/staticConfig`)
      .then((res) => {
        socialAxiosInstance.get("/config/static_config").then((res2) => {
          const data = { ...res.data.data, ...res2.data.data };
          resolve({ data: { data } });
        });
      })
      .catch((e) => {
        console.error("Error in fetchStaticConfigApi", e.response);
        reject(e);
      });
  });
}
export function fetchSpecificConfigApi(url, type = "GENERAL") {
  return new Promise((resolve, reject) => {
    const { specificConfig } = store.getState();
    if (specificConfig[url]) {
      return resolve({ status: 200, data: { data: specificConfig[url] } });
    }
    const instance =
      type === "GENERAL" ? mainAxiosInstance : socialAxiosInstance;
    instance
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          if (url.includes("search=%")) {
            store.dispatch(addSpecificConfig({ [url]: res.data.data }));
          }
        }
        return resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchSpecificConfigApi", e.response);
        return reject(e);
      });
    return null;
  });
}
export function addUserDefinedValueToMasterDBApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`/config/master`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in addUserDefinedValueToMasterDBApi", e.response);
        reject(e);
      });
  });
}
export function fetchPopularSkillsApi(type = 1) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(
        `config/popular/${type === 1 ? "skills" : "skill"}?limit=10&offset=1`,
        {}
      )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchPopularSkillsApi", e.response);
        reject(e);
      });
  });
}
export function fetchRelatedSkillsForDesignation(designationId) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/config/suggested_skills/designation/${designationId}`, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchRelatedSkillsForDesignation", e.response);
        reject(e);
      });
  });
}

function serializeData(data) {
  const params = new URLSearchParams();
  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'object') {
      // Convert object/array values into JSON strings
      params.append(key, JSON.stringify(data[key]));
    } else {
      // Append other values directly
      params.append(key, data[key]);
    }
  });
  return params.toString();
}

export function fetchRelatedJdForJobDataSSE(data, onDataReceived) {
  return new Promise((resolve, reject) => {  // Add this line
    const serializedParams = serializeData(data);
    const url = `${config.baseUrl}/jd/generate_jd?${serializedParams}`;
    const eventSource = new EventSource(url);
// let counter = 0
    eventSource.onmessage = function(event) {
      const receivedData = JSON.parse(event.data);
      if (!receivedData.endOfStream) {
        onDataReceived(receivedData);
        // counter+=1;
        // console.log(`JD Stream: ${receivedData} c: ${counter}`)
      } else {
        eventSource.close();
        resolve();  // Resolve the promise when streaming ends
      }
    };

    eventSource.onerror = function(error) {
      console.error("EventSource error:", error);
      eventSource.close();
      reject(error);  // Reject the promise on error
    };
  });
}




export function fetchRelatedJdForJobData(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`/offer/generate_jd`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchRelatedJdForJobData", e.response);
        reject(e);
      });
  });
}
export function fetchPopularDesignationsApi(limit = 10) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`config/popular/designation?limit=${limit}&offset=1`, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchPopularDesignationsApi", e.response);
        reject(e);
      });
  });
}
export function fetchPopularDataByTableApi(table, limit = 10) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`config/popular/${table}?limit=${limit}&offset=1`, {})
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in fetchPopularDataByTableApi", e.response);
        reject(e);
      });
  });
}
export function contactUsApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post("/feedback/contactus", data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in contactUsApi", e.response);
        reject(e);
      });
  });
}
export function trackLinkClickApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post("/tracking/link_clicked", data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in trackLinkClickApi", e.response);
        reject(e);
      });
  });
}
export function captureEventForAnalytics(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post("/tracking/capture_event", data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in captureEventForAnalytics", e.response);
        reject(e);
      });
  });
}

export function fetchUserIpDetailsApi() {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get("/utilities//ip_details")
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((e) => {
        console.error("Error in fetchUserIpDetailsApi", e.response);
        reject(e);
      });
  });
}
