export const convertToYearly = (monthlyVal) => {
  switch (monthlyVal) {
    case "₹ 10k - 25k":
    case "₹10k - 25k":
      return "₹ 1.2L - 3L";
    case "₹ 25k - 50k":
    case "₹25k - 50k":
      return "₹ 3L - 6L";
    case "₹ 50k - 75k":
      return "₹ 6L - 9L";
    case "₹ 75k - 100k":
      return "₹ 9L - 12L";
    case "₹ 100k - 150k":
      return "₹ 12L - 18L";
    case "₹ 150k - 200k":
      return "₹ 18L - 24L";
    case "₹ 200k - 300k":
      return "₹ 24L - 36L";
    case "₹ 300k - 500k":
      return "₹ 36L - 60L";
    case ">  ₹ 500k":
      return "> ₹ 60L";
    default:
      return monthlyVal;
  }
};
export const getYearlyCompensationFromId = (id) => {
  switch (id) {
    case 1:
      return "₹ 1.2L - 3L";
    case 2:
      return "₹ 3L - 6L";
    case 3:
      return "₹ 6L - 9L";
    case 4:
      return "₹ 9L - 12L";
    case 5:
      return "₹ 12L - 18L";
    case 6:
      return "₹ 18L - 24L";
    case 7:
      return "₹ 24L - 36L";
    case 8:
      return "₹ 36L - 60L";
    case 9:
      return "> ₹ 60L";
    default:
      return "";
  }
};
export const getHourlyCompensationFromId = (id) => {
  switch (id) {
    case 10:
      return "₹ 100 - 200";
    case 11:
      return "₹ 200 - 350";
    case 12:
      return "₹ 350 - 500";
    case 13:
      return "₹ 500 - 700";
    case 14:
      return "₹ 700 - 1000";
    case 15:
      return "₹ 1k - 1.5k";
    case 16:
      return "₹ 1.5k - 2k";
    case 17:
      return "₹ 2k - 3k";
    case 18:
      return "₹ 3k - 5k";
    case 19:
      return "> ₹ 5k";
    default:
      return "";
  }
};
export const getIdFromCompensation = (value) => {
  switch (value) {
    case "₹ 1.2L - 3L":
      return 1;
    case "₹ 3L - 6L":
      return 2;
    case "₹ 6L - 9L":
      return 3;
    case "₹ 9L - 12L":
      return 4;
    case "₹ 12L - 18L":
      return 5;
    case "₹ 18L - 24L":
      return 6;
    case "₹ 24L - 36L":
      return 7;
    case "₹ 36L - 60L":
      return 8;
    case "> ₹ 60L":
      return 9;
    case "₹ 100 - 200":
      return 10;
    case "₹ 200 - 350":
      return 11;
    case "₹ 350 - 500":
      return 12;
    case "₹ 500 - 700":
      return 13;
    case "₹ 700 - 1000":
      return 14;
    case "₹ 1k - 1.5k":
      return 15;
    case "₹ 1.5k - 2k":
      return 16;
    case "₹ 2k - 3k":
      return 17;
    case "₹ 3k - 5k":
      return 18;
    case "> ₹ 5k":
      return 19;
    default:
      return null;
  }
};

export const getPerYearOrHour = (value) => {
  const id = getIdFromCompensation(value);
  if (id > 9) return " /Hr";
  return " /Yr";
};

export const getCompensationFromIdNew = (id) => {
  if (typeof id !== "number") {
    // eslint-disable-next-line no-param-reassign
    id = getIdFromCompensation(convertToYearly(id));
  }
  switch (id) {
    case 1:
      return "1.2-3 LPA";
    case 2:
      return "3-6 LPA";
    case 3:
      return "6-9 LPA";
    case 4:
      return "9-12 LPA";
    case 5:
      return "12-18 LPA";
    case 6:
      return "18-24 LPA";
    case 7:
      return "24-36 LPA";
    case 8:
      return "36-60 LPA";
    case 9:
      return ">60 LPA";
    case 10:
      return "₹ 100 - 200";
    case 11:
      return "₹ 200 - 350";
    case 12:
      return "₹ 350 - 500";
    case 13:
      return "₹ 500 - 700";
    case 14:
      return "₹ 700 - 1000";
    case 15:
      return "₹ 1k - 1.5k";
    case 16:
      return "₹ 1.5k - 2k";
    case 17:
      return "₹ 2k - 3k";
    case 18:
      return "₹ 3k - 5k";
    case 19:
      return "> ₹ 5k";
    default:
      return "";
  }
};
