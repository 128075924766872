import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

const RemoveTrailingSlash = () => {
    const location = useLocation();

    if (location.pathname !== '/' && location.pathname.endsWith('/')) {
      return <Redirect to={location.pathname.slice(0, -1) + location.search} />;
    }
    return null; 
};

export default RemoveTrailingSlash;