import React, { useState } from 'react';
import styles from './FaqCard.module.scss';
import { IoIosArrowDown } from "react-icons/io";


import { IoAdd } from "react-icons/io5";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const FaqCard = ({ heading, description, isOpen, toggleAccordion }) => {
  const scrollToTop = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  };


  const parts = description.split(/(www.goodspace.ai\/hirer)/);
  const preLinkText = parts[0];
  const linkText = parts[1];
  const postLinkText = parts[2];

  return (
    <>

      <div className={styles.faqContainer} onMouseEnter={toggleAccordion} onMouseLeave={() => toggleAccordion(null)}>
        <div className={styles.accordionHeader}>
          <p className={styles.que}>{heading}</p>
          <div>
            {isOpen ? <IoIosArrowDown className={styles.expand} /> : <IoAdd className={styles.expand} />}
          </div>
        </div>
        {isOpen && (
          <div className={styles.ansContainer}>
            <p className={styles.ans}>
              {preLinkText}
              <a href="#top" onClick={scrollToTop} style={{ color: '#297BCA', textDecoration: 'underline' }}>
                {linkText}
              </a>
              {postLinkText}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default FaqCard;
