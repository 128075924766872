import React, { useState, useEffect } from "react";
import NotificationHelper from "@/pages/NotificationsPage/NotificationCard/NotificationHelper";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setNotificationCountData } from "@/redux/actions/notificationActions";
import {
  getNotificationsUnread,
  putNotification,
} from "@/services/userNotificationService";
import "@/pages/NotificationsPage/NotificationCard/NotificationCard.scss";

let cache = [];
const Notifications = React.forwardRef((props, ref) => {
  const { type } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const token = useSelector((state) => state.user.token);
  const userInfo = useSelector((state) => state.user);
  const notificationCount = useSelector(
    (state) =>
      state.notificationData[
        type === "SOCIAL_SERVICES"
          ? "notificationCountSocialServices"
          : "notificationCount"
      ]
  );
  const asynFunc = async () => {
    if (notificationCount) {
      setLoading(true);
      const data_ = cache.length
        ? cache
        : await getNotificationsUnread(1, 5, type || "");
      cache = data_;
      setData(data_.map((a) => ({ ...a, isShown: 1 })));
      const arr = [];
      data_.map((element) => arr.push(element.userNotificationId));
      await putNotification({ userNotificationId: arr });
      setLoading(false);
      dispatch(
        setNotificationCountData({
          [type === "SOCIAL_SERVICES"
            ? "notificationCountSocialServices"
            : "notificationCount"]: notificationCount - arr.length,
        })
      );
    }
  };
  useEffect(() => {
    asynFunc();
  }, [userInfo.userMode, type]);
  useEffect(() => {
    cache = [];
  }, [token, userInfo.userMode]);
  return (
    <div className="dashboardNavbar__notifications" ref={ref}>
      <div className="dashboardNavbar__notifications-notch">
        <svg viewBox="0 0 697.45 68.79">
          <path
            className="cls-1"
            d="M-281.13,487c347.46.49,602.16,0,606.26-1.18,3.38-1,6.69-2.16,6.69-2.16.83-.29,2.31-.84,4.05-1.53l16.06-6.35a6.43,6.43,0,0,1,2.24-.33,9.56,9.56,0,0,1,3.45.76,28.87,28.87,0,0,1,5,2.37,31.23,31.23,0,0,1,3.56,2.51,41,41,0,0,1,6.85,7.05,38.4,38.4,0,0,1,4.88,8.58,41.94,41.94,0,0,1,2.29,8,60.79,60.79,0,0,1,.58,18.59C224.55,531.48,110.47,535.47,33,537.6c-233.25,6.41-220.65-1.66-239.75,3.83,0,0-22.26,6.39-92.17,0-3.41-.31-10.33-1.16-14.3-6.36-3.15-4.13-3.13-9.3-2.82-12.37l.11-1.15a39.67,39.67,0,0,1,9.74-21.71A40.09,40.09,0,0,1-281.13,487Z"
            transform="translate(316.21 -475.48)"
          />
        </svg>
      </div>
      <div
        className="dashboardNavbar__notifications-list"
        style={!data.length && !loading ? { justifyContent: "center" } : {}}
      >
        {!data.length ? (
          <div className="dashboardNavbar__notifications-noNotif">
            {loading
              ? "Loading..."
              : "You don't have any new notifications at this time."}
          </div>
        ) : null}
        <NotificationHelper data={data} />
        <Link
          to={`${
            type === "SOCIAL_SERVICES" ? "/blood-donors" : ""
          }/notifications`}
          className="dashboardNavbar__notifications-btn"
        >
          See All
        </Link>
      </div>
    </div>
  );
});
export default Notifications;
