import React, { useRef } from "react";
import handshake from "@/assets/png/handshake.png";
import PropTypes from "prop-types";
import styles from "./SwitchModePopup.module.scss";

// eslint-disable-next-line no-unused-vars
export default function SwitchModePopup({
  changeAppMode,
  activeMode,
  open,
  close,
}) {
  const modal = useRef(null);
  const hidePopup = (e) => {
    if (modal.current && !modal.current.contains(e.target)) {
      console.log(modal.current.contains(e.target));
      close();
    }
  };
  return (
    <div
      className={`${styles.superContainer} ${
        open ? styles.superContainerOpen : ""
      }`}
      onClick={hidePopup}
    >
      <div className={styles.topArrow}>
        <svg>
          <path
            fill="#fafafa"
            d="M0,50,12.18,10.42C12.76,7.77,14.49,6,15.83,6s3,2,3.17,5q-.24,24.75-.49,49.5Z"
            transform="translate(0 -5.97)"
          />
        </svg>
      </div>
      <div className={styles.container} ref={modal}>
        <div
          onClick={() => changeAppMode("JOB_SEEKER")}
          className={`${styles.listItem} ${
            activeMode === "JOB_SEEKER" ? styles.listItemActive : ""
          }`}
        >
          <div className={styles.listContent}>
            <div className={styles.listContentTitle}>
              Find Work <JobSeekerIcon />
            </div>
            <div className={styles.listContentText}>
              Global work opportunities to boost your professional life
            </div>
          </div>
          <div className={styles.activeDot} />
        </div>
        <div
          onClick={() => changeAppMode("HIRING")}
          className={`${styles.listItem} ${
            activeMode === "HIRING" ? styles.listItemActive : ""
          }`}
        >
          <div className={styles.listContent}>
            <div className={styles.listContentTitle}>
              Hire Talent <HirerIcon />
            </div>
            <div className={styles.listContentText}>
              Find employees, freelancers, consultants, contractors across 3000+
              skill categories
            </div>
          </div>
          <div className={styles.activeDot} />
        </div>
        <div
          onClick={() => changeAppMode("SOCIAL")}
          className={`${styles.listItem} ${
            activeMode === "SOCIAL" ? styles.listItemActive : ""
          }`}
        >
          <div className={styles.listContent}>
            <div className={styles.listContentTitle}>
              Find Friends <SocialIcon />
            </div>
            <div className={styles.listContentText}>
              Find new friends, create social experiences like backpacking trip,
              music events, etc.
            </div>
          </div>
          <div className={styles.activeDot} />
        </div>
        <div
          onClick={() => changeAppMode("SOCIAL_SERVICES")}
          className={`${styles.listItem} ${
            activeMode === "SOCIAL_SERVICES" ? styles.listItemActive : ""
          }`}
        >
          <div className={styles.listContent}>
            <div className={styles.listContentTitle}>
              Find Blood Donors <BloodIcon />
            </div>
            <div className={styles.listContentText}>
              Every minute counts: Find a blood donor and help save a life
              today.
            </div>
          </div>
          <div className={styles.activeDot} />
        </div>
      </div>
    </div>
  );
}
const JobSeekerIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0935 3.99584H11.7431V2.3573C11.7431 1.4479 10.9976 0.71875 10.0679 0.71875H6.71745C5.7877 0.71875 5.04224 1.4479 5.04224 2.3573V3.99584H1.69181C0.762071 3.99584 0.0249776 4.725 0.0249776 5.63439L0.0166016 14.6464C0.0166016 15.5558 0.762071 16.2849 1.69181 16.2849H15.0935C16.0232 16.2849 16.7687 15.5558 16.7687 14.6464V5.63439C16.7687 4.725 16.0232 3.99584 15.0935 3.99584ZM10.0679 3.99584H6.71745V2.3573H10.0679V3.99584Z"
      fill="black"
    />
  </svg>
);

const HirerIcon = () => <img src={handshake} alt="" />;

const SocialIcon = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8184 0.0556641H1.74632C0.985111 0.0556641 0.369225 0.666531 0.369225 1.41315L0.362305 13.6305L3.13033 10.9155H12.8184C13.5796 10.9155 14.2024 10.3047 14.2024 9.55805V1.41315C14.2024 0.666531 13.5796 0.0556641 12.8184 0.0556641ZM3.13033 4.80685H11.4344V6.16434H3.13033V4.80685ZM8.66639 8.20056H3.13033V6.84308H8.66639V8.20056ZM11.4344 4.12811H3.13033V2.77063H11.4344V4.12811Z"
      fill="black"
    />
  </svg>
);
SwitchModePopup.propTypes = {
  changeAppMode: PropTypes.func.isRequired,
  activeMode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
const BloodIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="15"
    height="14"
    viewBox="0 0 112.000000 160.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,160.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path d="M518 1553 c-201 -212 -417 -562 -484 -788 -37 -124 -39 -288 -5 -385 29 -83 99 -188 159 -240 92 -80 252 -140 372 -140 74 0 180 27 259 66 147 73 263 230 291 396 42 240 -103 593 -388 948 -86 107 -151 180 -161 180 -4 0 -23 -17 -43 -37z" />
    </g>
  </svg>
);
