import React from "react";
import FaqCard from "@/pages/AutoRecruiterLandingPage/components/faqCard/FaqCard";
import Navbar from "@/dashboards/Navbar";
import data from "./faqQuestions.json";
import styles from "./FaqPageByMode.module.scss";

export default function FaqPageByMode({ userMode }) {
  return (
    <div className={styles.page}>
      <Navbar />
      <div className={styles.container}>
        <div className={styles.title}>
          Frequently Asked Questions for{" "}
          {userMode === "HIRING" ? "Hirers " : "Job Seeker"}
        </div>
        <div className={styles.faqContainer}>
          {data[userMode === "HIRING" ? "hirerFaq" : "jobSeekerFaq"].map(
            (item) => (
              <FaqCard heading={item.question} description={item.ans} />
            )
          )}
        </div>
      </div>
    </div>
  );
}
