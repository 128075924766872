import React from "react";
import PropTypes from "prop-types";
import { AiOutlineClose } from "react-icons/ai";
import Logo from "@/assets/logo/logo.svg";
import styles from "./DownloadAppBanner.module.css";

export default function DownloadAppBanner({ hideBanner, bannerIsOpen }) {
  return (
    <div
      className={`${styles.bannerContainer} ${
        bannerIsOpen ? styles.bannerContainerOpen : ""
      }`}
    >
      <div className={styles.closeButton} onClick={hideBanner}>
        <AiOutlineClose />
      </div>
      <div className={styles.logo}>
        <img src={Logo} alt="" />
      </div>
      <div className={styles.text}>
        Download the GoodSpace App for a Seamless Experience.
      </div>
      <a
        target="_blank"
        href="https://ourgoodspace.page.link/tcsEh1uRe1QR5bwW8"
        className={styles.donwloadBtn}
        rel="noreferrer"
      >
        Download
      </a>
    </div>
  );
}
DownloadAppBanner.propTypes = {
  hideBanner: PropTypes.func.isRequired,
  bannerIsOpen: PropTypes.bool.isRequired,
};
