import { useSelector } from "react-redux";
import Intercom from "@intercom/messenger-js-sdk";
import appConfig from "@/config/appConfig";

async function generateUserHash(userIdentifier, secretKey) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(secretKey);
  const userData = encoder.encode(userIdentifier);

  const key = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: { name: "SHA-256" } },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign("HMAC", key, userData);
  return Array.from(new Uint8Array(signature))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
}

export default function IntercomComponent() {
  const user = useSelector((state) => state.user);
  // console.log(user);

  if (user.user_id) {
    const userIdentifier = user.user_id.toString();
    generateUserHash(userIdentifier, appConfig.intercomSecretKey)
      .then((hash) => {
        Intercom({
          app_id: appConfig.intercomAppId,
          user_id: user.user_id,
          name: user.name,
          email: user.email_id,
          phone: user.mobile_number,
          utm_term: user.userMode,
          user_hash: hash,
        });
      })
      .catch(console.error);
  }

  return null;
}
