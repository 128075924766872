/* eslint-disable import/prefer-default-export */
import store from "@/redux/store";
import { setNotificationCountData } from "@/redux/actions/notificationActions";
import {
  mainAxiosInstance,
  socialAxiosInstance,
} from "./networkAdapters/axiosInstance";

export function getNotificationData(type) {
  return new Promise((resolve, reject) => {
    const { user } = store.getState();
    const userMode = type || user.userMode;
    const Axios =
      userMode !== "SOCIAL" ? mainAxiosInstance : socialAxiosInstance;

    Axios.get(
      `/notification/count?type=${userMode === "HIRING" ? "HIRER" : userMode}`
    )
      .then((res) => {
        if (res.status === 200) {
          store.dispatch(
            setNotificationCountData({
              notificationCount: res.data.data.notification,
              notificationCountSocialServices:
                res.data.data.notificationCountSocialServices,
              messageCount: res.data.data.chat,
              socialServicesChatCount: res.data.data.socialServicesChat,
            })
          );
        }
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getProfileDetailsAlternateApi", e.response);
        reject(e);
      });
  });
}
