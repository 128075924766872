import store from "@/redux/store";
import {
  mainAxiosInstance,
  socialAxiosInstance,
} from "./networkAdapters/axiosInstance";

export const getNotifications = (page, type) =>
  new Promise((resolve, reject) => {
    const { user } = store.getState();
    const Axios =
      user.userMode !== "SOCIAL" ? mainAxiosInstance : socialAxiosInstance;
    Axios.get(
      `/notification/getAll?limit=10&page=${page}&type=${type || (user.userMode === "HIRING" ? "HIRER" : user.userMode)
      }`
    )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((e) => {
        console.error("Error in getNotifications", e.response);
        reject(e);
      });
  });
export const getNotificationsUnread = (page, limit, type) =>
  new Promise((resolve, reject) => {
    const { user } = store.getState();
    const Axios =
      user.userMode !== "SOCIAL" ? mainAxiosInstance : socialAxiosInstance;
    Axios.get(
      `/notification/new?limit=${limit}&page=${page}&type=${type || (user.userMode === "HIRING" ? "HIRER" : user.userMode)
      }`
    )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((e) => {
        console.error("Error in getNotificationsUnread", e.response);
        reject(e);
      });
  });

export const getNotificationCount = () =>
  new Promise((resolve, reject) => {
    const { user } = store.getState();
    const Axios =
      user.userMode !== "SOCIAL" ? mainAxiosInstance : socialAxiosInstance;
    Axios.get(`/notification/count`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((e) => {
        console.error("Error in completeOnBoardingApi", e.response);
        reject(e);
      });
  });

export const putNotification = (data) =>
  new Promise((resolve, reject) => {
    const { user } = store.getState();
    const Axios =
      user.userMode !== "SOCIAL" ? mainAxiosInstance : socialAxiosInstance;
    Axios.put(`/notification`, data)
      .then((res) => {
        console.log(res.data);
        resolve(res.data);
      })
      .catch((e) => {
        console.error("Error in completeOnBoardingApi", e.response);
        reject(e);
      });
  });
