import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./ChatInput.module.scss";

export default function ChatInput({ sendMessage: sendMessageProps }) {
  const textInp = useRef(null);
  const [message, setMessage] = useState("");
  const handleChange = (e) => setMessage(e.target.value);
  const sendMessage = () => {
    if (message) {
      sendMessageProps(message);
      setMessage("");
    }
  };
  const autosize = (evt) => {
    if (!textInp.current) return;
    if (evt && evt.keyCode === 13 && !evt.shiftKey) {
      sendMessage();
      evt.preventDefault();
      return;
    }
    const el = textInp.current;

    setTimeout(() => {
      console.log(el.scrollHeight);
      if (el.offsetHeight > 60 && el.scrollHeight > el.offsetHeight) return;
      el.style.cssText = "height:auto; padding:0rem";
      el.style.cssText = `height:${el.scrollHeight + 5}px`;
    }, 0);
  };
  useEffect(() => {
    autosize();
  }, [message]);
  return (
    <div className={styles.container}>
      <textarea
        name=""
        id=""
        className={styles.textarea}
        placeholder="Type Message"
        value={message}
        onChange={handleChange}
        onKeyDown={autosize}
        ref={textInp}
        rows="1"
      />
      <div className={styles.sendIcon} onClick={sendMessage}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.7352 11.9902L3.6586 13.5037C3.55425 13.5211 3.45632 13.5657 3.37467 13.633C3.29301 13.7003 3.23051 13.7878 3.19344 13.8869L0.0638802 22.2706C-0.234977 23.0418 0.571214 23.7769 1.31113 23.407L23.0024 12.5614C23.1526 12.4863 23.279 12.3709 23.3673 12.2281C23.4556 12.0853 23.5024 11.9207 23.5024 11.7528C23.5024 11.5848 23.4556 11.4202 23.3673 11.2774C23.279 11.1346 23.1526 11.0192 23.0024 10.9442L1.31113 0.0985377C0.571214 -0.271419 -0.234977 0.464879 0.0638802 1.23492L3.19465 9.61858C3.23154 9.7179 3.29397 9.80573 3.37564 9.87322C3.4573 9.94071 3.55532 9.98548 3.65981 10.003L12.7364 11.5154C12.7922 11.5251 12.8429 11.5543 12.8793 11.5977C12.9158 11.6412 12.9358 11.6961 12.9358 11.7528C12.9358 11.8095 12.9158 11.8644 12.8793 11.9078C12.8429 11.9512 12.7922 11.9804 12.7364 11.9902H12.7352Z"
            fill="#389fff"
          />
        </svg>
      </div>
    </div>
  );
}
ChatInput.propTypes = {
  sendMessage: PropTypes.func.isRequired
};
