import axios from "axios";
import config from "@/config/appConfig";
import store from "@/redux/store";

const chatInstance = axios.create({
  baseURL: config.chatBaseUrl,
});

chatInstance.interceptors.request.use((instanceConfig) => {
  const { token } = store.getState().user;
  // eslint-disable-next-line no-param-reassign
  instanceConfig.headers.Authorization = token;
  return instanceConfig;
});
export default chatInstance;
