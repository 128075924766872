import queryClient from "@/reactQuery/queryClient";
import store from "@/redux/store";
import { updateUserDetails } from "@/redux/actions/userActions";
import { setNotificationCountData } from "@/redux/actions/notificationActions";
import { mainAxiosInstance } from "./networkAdapters/axiosInstance";
import { getNotificationData } from "./notificationService";
import  createSSEConnection from "./networkAdapters/sseInstance";

export function completeOnBoardingApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post("/profile/basicDetails", data)
      .then((res) => {
        const { user_id } = store.getState().user;
        mainAxiosInstance.get(`/profile/${user_id}`).then((res2) => {
          store.dispatch(
            updateUserDetails({
              completeDataFetched: true,
              hirerModeUnlocked: res2.data.data.hirerModeUnlocked,
              sendOfferDisabled: res2.data.data.sendOfferDisabled,
              isProfileVerified: res2.data.data.isProfileVerified,
              account_type_id: res2.data.data.account_type_id,
              purchase_validity: res2.data.data.purchase_validity,
              is_premium: res2.data.data.is_premium,
              skills: res2.data.data.skill,
              dob: new Date(res2.data.data.dob),
              experience: res2.data.data.experience
                ? res2.data.data.experience
                : res2.data.data.workExperience,
              education: res2.data.data.education,
              bio: res2.data.data.bio,
              current_availability: res2.data.data.current_availability,
              purpose: res2.data.data.purpose,
              hourlyCompensation: res2.data.data.hourlyCompensation,
              monthlyCompensation: res2.data.data.monthlyCompensation,
              totalWorkExperience: res2.data.data.totalWorkExperience,
              links: res2.data.data.links,
              location_city: res2.data.data.location_city,
              profileCompletion: res2.data.data.profileCompletion,
              userDesignation: res2.data.data.userDesignation,
              email_id: res2.data.data.email_id,
              highest_qualification: res2.data.data.highest_qualification,
              organisation: res2.data.data.organisation,
              parameters: res2.data.data.parameters,
              reviews: res2.data.data.reviews,
              selfFeedback: res2.data.data.selfFeedback,
              referralCode: res2.data.data.referralCode,
              gcoins: res2.data.data.gcoins,
              workExperienceRange: res2.data.data.workExperienceRange,
              jobData: res2.data.data.jobData,
              freelanceData: res2.data.data.freelanceData,
              actively_professionals: res2.data.data.actively_professionals,
              entrepreneurData: res2.data.data.entrepreneurData,
              highestEducationData: res2.data.data.highestEducationData,
              userSeekedOpportunities: res2.data.data.userSeekedOpportunities,
              volunteeringData: res2.data.data.volunteeringData,
              currency: res2.data.data.currency,
              projects:res2.data.data.projects,
              resume:res2.data.data.resume,
              languages:res2.data.data.languages,
              certificates:res2.data.data.certificates,
              awards:res2.data.data.awards,
              gender:res2.data.data.gender,
              mobileNumber:res2.data.data.mobileNumber,
            })
          );
          const inviteUserId = window.localStorage.getItem("inviteUserId");
          if (inviteUserId) {
            mainAxiosInstance
              .post(`/tzpoints/invite`, {
                hostUser: inviteUserId
              })
              .then((res2) => {
                console.log(res2.data);
                resolve(res);
              })
              .catch((e) => {
                console.error("Error in invite Api", e.response);
                resolve(e);
              });
          } else resolve(res);
        });
      })
      .catch((e) => {
        console.error("Error in completeOnBoardingApi", e.response);
        reject(e);
      });
  });
}
export function getOnboardingProgressApi() {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get("/onboarding/data/V2")
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getOnboardingProgress", e.response);
        reject(e);
      });
  });
}
export function saveOnboardingStepApi(step, data) {
  return new Promise((resolve, reject) => {
    
    mainAxiosInstance
      .post(`/onboarding/${step}`, data)
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getOnboardingProgress", e.response);
        reject(e);
      });
  });
}

export function getProfileDetailsApi(userId_, forced) {
  return new Promise((resolve, reject) => {
    const { user } = store.getState();
    const userId = userId_ || user.user_id;
    if (`${user.user_id}` === `${userId}` && user.status === 2 && !forced) {
      return "";
    }

    if (
      !forced &&
      `${user.user_id}` === `${userId}` &&
      user.completeDataFetched
    ) {
      return resolve({ data: { data: { ...user } } });
    }
    mainAxiosInstance
      .get(`/profile/${userId}`)
      .then((res) => {
        if (forced && `${user.user_id}` === `${userId}`)
          store.dispatch(
            updateUserDetails({
              completeDataFetched: true,
              hirerModeUnlocked: res.data.data.hirerModeUnlocked,
              isProfileVerified: res.data.data.isProfileVerified,
              skills: res.data.data.skill,
              dob: new Date(res.data.data.dob),
              experience: res.data.data.experience
                ? res.data.data.experience
                : res.data.data.workExperience,
              education: res.data.data.education,
              bio: res.data.data.bio,
              monthlyCompensation: res.data.data.monthlyCompensation,
              totalWorkExperience: res.data.data.totalWorkExperience,
              score: res.data.data.score,
              links: res.data.data.links,
              profileCompletion: res.data.data.profileCompletion,
              userDesignation: res.data.data.userDesignation,
              highest_qualification: res.data.data.highest_qualification,
              referralCode: res.data.data.referralCode,
              workExperienceRange: res.data.data.workExperienceRange,
              jobData: res.data.data.jobData,
              highestEducationData: res.data.data.highestEducationData,
              currency: res.data.data.currency,
              projects:res.data.data.projects,
              resume:res.data.data.resume,
              languages:res.data.data.languages,
              certificates:res.data.data.certificates,
              awards:res.data.data.awards,
              gender:res.data.data.gender,
              parameters: res.data.data.parameters,
            },"JOB_SEEKER")
          );
        store.dispatch(
          setNotificationCountData({
            newCandidateApplyCount: res.data.data.newCandidateApplyCount,
            newOfferReceivedCount: res.data.data.newOfferReceivedCount
          })
        );
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getProfileDetailsApi", e.response);

        return reject(e);
      });
    return {};
  });
}
export function getProfileForHirer(userId_, forced) {
  return new Promise((resolve, reject) => {
    const { user } = store.getState();
    const userId = userId_ || user.user_id;
    if (user.status === 2 && !forced) {
      return "";
    }

    if (
      !forced &&
      `${user.user_id}` === `${userId}` &&
      user.completeDataFetched
    ) {
      return resolve({ data: { data: { ...user.hiring } } });
    }
    mainAxiosInstance
      .get(`/profile/hirer`)
      .then((res) => {
        if (forced && `${user.user_id}` === `${userId}`)
          store.dispatch(
            updateUserDetails({
              userSavedMode: res.data.data.userSavedMode,
              location_city: res.data.data.user.location_city,
              isProfileVerified: res.data.data.user.isProfileVerified,
              referralCode: res.data.data.user.referralCode,
              gcoins: res.data.data.user.gcoins,
              profileStrength: res.data.data.user.profileStrength,
              company_gst: res.data.data.userHiringOrganisation.company_gst,
              organisation_id: res.data.data.userHiringOrganisation.organisation_id,
              organisation: res.data.data.userHiringOrganisation.organisation,
              designation: res.data.data.userHiringOrganisation.designation,
              designation_id: res.data.data.userHiringOrganisation.designation_id,
              industry_type: res.data.data.userHiringOrganisation.industry_type,
              industry_type_id: res.data.data.userHiringOrganisation.industry_type_id,
              annual_turnover: res.data.data.userHiringOrganisation.annual_turnover,
              annual_turnover_id: res.data.data.userHiringOrganisation.annual_turnover_id,
              website: res.data.data.userHiringOrganisation.website,
              address: res.data.data.userHiringOrganisation.address,
              company_size: res.data.data.userHiringOrganisation.company_size,
              linkedin_url: res.data.data.userHiringOrganisation.linkedin_url,
              description: res.data.data.userHiringOrganisation.description,
            }, "HIRING")
          );
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getProfileDetailsApi", e.response);

        return reject(e);
      });
    return {};
  });
}
export function getProfileDetailsAlternateApi(userId) {
  return new Promise((resolve, reject) => {
    const { user } = store.getState();
    if (user.status === 2 ) {
      return "";
    }

    if (
      `${user.user_id}` === `${userId}` &&
      user.completeDataFetched
    ) {
      return resolve({ data: { data: { ...user } } });
    }
    mainAxiosInstance
      .get(`/profile/userInfo?id=${userId}`)
      .then((res) => {
        store.dispatch(
          updateUserDetails({
            profile_percentage:res.data.data.profile_percentage,
            isProfileVerified:res.data.data.isProfileVerified,
            score:res.data.data.score,
            
          },"JOB_SEEKER")
        );
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getProfileDetailsAlternateApi", e.response);
        reject(e);
      });
  });
}
export function getResumeParsingStatusApi(needToCompleteOnboarding) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(
        `/profile/resume/parsed?needToCompleteOnboarding=${needToCompleteOnboarding || "false"
        }`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getResumeParsingStatusApi", e.response);
        reject(e);
      });
  });
}

export function listenToResumeParsingStatus({ onProgress, onComplete, onError }) {
  const { user } = store.getState();
  const queryParams = {
    userId: user.user_id,
  };

  return createSSEConnection({
    route: 'resume/parsed',  
    queryParams,
    onProgress,
    onComplete,
    onError,
  });
}


export function getProfileDetailsByKeyApi(keys, userId) {
  return new Promise((resolve, reject) => {
    const { user } = store.getState();
    mainAxiosInstance
      .get(`/profile/${userId || user.user_id}/by_keys?keys=${keys.join(",")}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getProfileDetailsByKeyApi", e.response);
        reject(e);
      });
  });
}
export function getTzPointsHistoryApi() {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/tzpoints/history`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in getTzPointsHistoryApi", e.response);
        reject(e);
      });
  });
}
export function getProfileFeedbacksDataApi(userId, id) {
  return new Promise((resolve, reject) => {
    if (Number.isNaN(parseInt(userId, 10))) {
      return reject(new Error("UserId is invalid"));
    }
    const { user } = store.getState();
    if (user.status === 2) {
      return "";
    }
    mainAxiosInstance
      .get(`/profile/feedback?id=${userId}&&relationship_id=${id}`)
      .then((res) => resolve(res))
      .catch((e) => {
        console.error("Error in getProfileFeedbacksDataApi", e.response);
        return reject(e);
      });
    return null;
  });
}
export function updateProfilePicApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post("profile/profilePic", data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in updateProfilePicApi", e.response);
        reject(e);
      });
  });
}

export function updateSocialLinksApi(links) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`profile/links`, { links })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in updateSocialLinksApi", e.response);
        reject(e);
      });
  });
}
export function updateProfileDetailsApi(data, userId) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .put(`profile/about?id=${userId}`, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in updateProfileDetailsApi", e.response);
        reject(e);
      });
  });
}
export function updateProfileByCategoryApi(type, category, id, data) {
  return new Promise((resolve, reject) => {
    let url = `profile/${category}`;
    if (id) {
      url += `?id=${id}`;
    }
    if (type === "post")
      mainAxiosInstance
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.error(
            "Error in updateProfileByCategoryApi - Post",
            e.response
          );
          reject(e);
        });
    else if (type === "put")
      mainAxiosInstance
        .put(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.error(
            "Error in updateProfileByCategoryApi - Put",
            e.response
          );
          reject(e);
        });
    if (type === "delete")
      mainAxiosInstance
        .delete(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.error(
            "Error in updateProfileByCategoryApi - Delete",
            e.response
          );
          reject(e);
        });
  });
}

export function addUserToFavouritesApi(userId) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`profile/add_to_favourites`, { target_user_id: userId })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in addUserToFavouritesApi", e.response);
        reject(e);
      });
  });
}
export function removeUserFromFavouritesApi(userId) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post(`profile/remove_from_favourites`, { target_user_id: userId })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in removeUserFromFavouritesApi ", e.response);
        reject(e);
      });
  });
}

export function switchUserAppMode(newMode) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .put(`profile/user_mode`, { userMode: newMode })
      .then((res) => {
        queryClient.clear();
        resolve(res);
        getNotificationData(newMode);
      })
      .catch((e) => {
        console.error("Error in switchUserModeApi ", e.response);
        reject(e);
      });
  });
}

export function updateUserLocation(lat, long, city) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .put(`profile/coordinates`, { latitude: lat, longitude: long, city })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in switchUserModeApi ", e.response);
        reject(e);
      });
  });
}
