import {
    mainAxiosInstance as axiosInstance,
    socialAxiosInstance
  } from "@/services/networkAdapters/axiosInstance";

  const submitForm = (url, encRequest, accessCode) => {
    // Create form and append to the body
    const form = document.createElement('form');
    form.method = 'post';
    form.action = url;
    form.style.display = 'none';
  
    // Create encRequest input and append to the form
    const encRequestInput = document.createElement('input');
    encRequestInput.type = 'hidden';
    encRequestInput.name = 'encRequest';
    encRequestInput.value = encRequest;
    form.appendChild(encRequestInput);
  
    // Create access_code input and append to the form
    const accessCodeInput = document.createElement('input');
    accessCodeInput.type = 'hidden';
    accessCodeInput.name = 'access_code';
    accessCodeInput.value = accessCode;
    form.appendChild(accessCodeInput);
  
    // Append form to the body and submit
    document.body.appendChild(form);
    form.submit();
  };

  const initiateJobSeekerPremiumPlanPurchase = async (planName) => {
    try {
      const res = await axiosInstance.post("/member/initiate_purchase_ccAvenue", {
        planName,
        currency: "INR"
      });
      if (res.data && res.data.data && res.data.data.url && res.data.data.encRequest && res.data.data.accessCode) {
        submitForm(res.data.data.url, res.data.data.encRequest, res.data.data.accessCode);
      } else {
        throw new Error("Invalid response from server");
      }
    }catch(error){
      console.error("Error initiating purchase: ", error);
    }
    
  };
  const initiateJobSeekerPremiumPlanPurchaseV2 = async (planName, planLocation ="DEFAULT") => {
    try {
      const res = await axiosInstance.post("/member/initiate_purchase_ccAvenueV3", {
        planName,
        planLocation,
        currency: "INR"
      });
      if (res.data && res.data.data && res.data.data.url && res.data.data.encRequest && res.data.data.accessCode) {
        submitForm(res.data.data.url, res.data.data.encRequest, res.data.data.accessCode);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error initiating purchase: ", error);
    }
  };
  const initiateJobSeekerGcoinsPurchase = async (gCoinsCode) => {
    try {
      const res = await axiosInstance.post("/gcoins/create_order_ccAvenue", {
        gCoinsCode
      });
      if (res.data && res.data.data && res.data.data.url && res.data.data.encRequest && res.data.data.accessCode) {
        submitForm(res.data.data.url, res.data.data.encRequest, res.data.data.accessCode);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error initiating purchase: ", error);
    }
  
  };
  const initiateRecruiterGcoinsPurchase = async (amount) => {
    try {
      const res = await axiosInstance.post(
        "/gcoins/recruiter/create_order_ccAvenue",
        {
          amount,
          currency: "INR"
        }
      );
      if (res.data && res.data.data && res.data.data.url && res.data.data.encRequest && res.data.data.accessCode) {
        submitForm(res.data.data.url, res.data.data.encRequest, res.data.data.accessCode);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error initiating purchase: ", error);
    }
    
  };

  const intiatePitchPurchase = async (planName) => {
    try {
      const res = await axiosInstance.post("/pitch/init_purchase_ccAvenue", {
        planName,
        currency: "INR"
      });
      if (res.data && res.data.data && res.data.data.url && res.data.data.encRequest && res.data.data.accessCode) {
        submitForm(res.data.data.url, res.data.data.encRequest, res.data.data.accessCode);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Error initiating purchase: ", error);
    }

  };
  // social
  
  const initiateSocialPlanPurchase = async (planName) => {
    try{
      const res = await socialAxiosInstance.post(
        "/premium/init_purchase_add_on_ccAvenue",
        {
          planName,
          currency: "INR"
        }
      );
      if (res.data && res.data.data && res.data.data.url && res.data.data.encRequest && res.data.data.accessCode) {
        submitForm(res.data.data.url, res.data.data.encRequest, res.data.data.accessCode);
      } else {
        throw new Error("Invalid response from server");
      }
    }
    catch (error) {
      console.error("Error initiating purchase: ", error);
    }
  };
  const intiateIntroPurchase = async (planName) => {
    try {
      const res = await socialAxiosInstance.post(
        "/premium/init_intro_add_on_ccAvenue",
        {
          planName,
          currency: "INR"
        }
      );
      if (res.data && res.data.data && res.data.data.url && res.data.data.encRequest && res.data.data.accessCode) {
        submitForm(res.data.data.url, res.data.data.encRequest, res.data.data.accessCode);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      throw new Error("Invalid response from server");
    }
    
  };
  
  export {
    initiateJobSeekerPremiumPlanPurchase,
    initiateJobSeekerGcoinsPurchase,
    initiateRecruiterGcoinsPurchase,
    intiatePitchPurchase,
    intiateIntroPurchase,
    initiateSocialPlanPurchase,
    initiateJobSeekerPremiumPlanPurchaseV2
  };
  