import { ADD_SPECIFIC_CONFIG } from "../CONSTANTS";

const initialState = {};
const specificConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SPECIFIC_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default specificConfigReducer;
