import React, { useState, useEffect } from "react";
import SocialLimitExhaustModal from "@/dashboards/Social/dashboard/components/LimitExhaustModal";
import PropTypes from "prop-types";
import { socialAxiosInstance as Axios } from "@/services/networkAdapters/axiosInstance";
import CustomSelectWrapper from "@/components/CustomSelectV2/CustomSelect";
import { showToastMessage } from "@/components/ToastComponent";
import styles from "./AddSocialExperienceModal.module.scss";

export default function AddSocialExperienceModal({
  hideModal,
  editMode,
  data: dataProps,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showLimitExhaustModal, setShowLimitExhaustModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const selectTag = ({ label, value }) => {
    if (selectedTags.findIndex((a) => a.value === value) > -1) {
      showToastMessage("error", "Tag already selected.");
    } else {
      setSelectedTags((a) => [...a, { label, value }]);
    }
  };
  const removeTag = (value) => {
    setSelectedTags((a) => [...a.filter((b) => b.value !== value)]);
  };
  const validate = () => {
    if (!title) {
      showToastMessage("error", "Title cannot be empty.");
      return false;
    }
    if (title.length > 50) {
      showToastMessage("error", "Title cannot be longer than 50 characters.");
      return false;
    }
    if (!description) {
      showToastMessage("error", "Description cannot be empty.");
      return false;
    }
    if (description.length > 250) {
      showToastMessage(
        "error",
        "Description cannot be longer than 250 characters."
      );
      return false;
    }
    if (!selectedTags.length) {
      showToastMessage("error", "Tags cannot be empty.");
      return false;
    }
    return true;
  };
  const updateExperience = () => {
    if (!dataProps.socialExpId && !validate()) {
      return;
    }
    const data = {
      title,
      socialExpId: dataProps.socialExpId,
      description,
      interestIdArray: selectedTags.map((a) => a.value),
    };
    Axios.put("/experience", data)
      .then((res) => {
        showToastMessage("info", res.data.message);
        setTimeout(hideModal, 1000);
      })
      .catch((err) => {
        showToastMessage("error", err.response.data.message);
      });
  };
  const saveExperience = () => {
    if (!validate()) {
      return;
    }
    const data = {
      title,
      description,
      interestIdArray: selectedTags.map((a) => a.value),
    };
    Axios.post("/experience", data)
      .then((res) => {
        showToastMessage("info", res.data.message);
        setTimeout(hideModal, 1000);
      })
      .catch((err) => {
        if (err.response?.data?.data?.error === "LIMIT_EXHAUST") {
          setShowLimitExhaustModal(true);
        } else showToastMessage("error", err.response.data.message);
      });
  };
  useEffect(() => {
    if (editMode) {
      setTitle(dataProps.title);
      setDescription(dataProps.description);
      setSelectedTags(
        dataProps.tags.map((a) => ({ label: a.value, value: a.id }))
      );
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Create an <span className={styles.titleBlue}>Experience</span>
      </div>
      <div className={styles.form}>
        <div className={styles.formLeft}>
          <div className={styles.formItem}>
            <div className={styles.formLabel}>Title</div>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={styles.formTextField}
              placeholder="Eg. Looking for Fitness Buddy"
            />
          </div>
          <div className={styles.formItem}>
            <div className={styles.formLabel}>Tags</div>
            <div className={styles.formSelect}>
              <CustomSelectWrapper
                searchType="interest"
                dataServer="SOCIAL"
                theme="socialCustom"
                placeholder="Eg. Fitness"
                onChange={selectTag}
              />
            </div>
            <div className={styles.formSelectedOptions}>
              {selectedTags.map((item) => (
                <div
                  key={item.value}
                  className={styles.formSelectedOption}
                  onClick={() => removeTag(item.value)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.formRight}>
          <div className={styles.formItem}>
            <div className={styles.formLabel}>Description</div>
            <textarea
              className={styles.formTextArea}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <button
          className={`${styles.button} ${styles.buttonAccept}`}
          onClick={editMode ? updateExperience : saveExperience}
        >
          {editMode ? "Update" : "Create"}
        </button>
        <button
          className={`${styles.button} ${styles.buttonCancel}`}
          onClick={hideModal}
        >
          Cancel
        </button>
      </div>{" "}
      <SocialLimitExhaustModal
        type="EXPERIENCE"
        isVisible={showLimitExhaustModal}
        hideModal={() => setShowLimitExhaustModal(false)}
      />
    </div>
  );
}
AddSocialExperienceModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  data: PropTypes.object,
};
AddSocialExperienceModal.defaultProps = {
  editMode: false,
  data: null,
};
