/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/prefer-default-export
export const removeUndefinedKeys = (obj) => {
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);
};
export const titleCase = (str) => {
  if (!str) return "";
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const calculateWorkDuration = (experience) => {
  const CURRENT_YEAR = new Date().getFullYear();
  const CURRENT_MONTH = new Date().getMonth();
  let count = 0;
  const totalWorkExArray = [];
  let endYear;
  let endMonth;
  let startMonth;
  const earliestYear = experience[experience.length - 1].start_date_year;
  const size = CURRENT_YEAR - earliestYear + 1;
  for (let i = 0; i < size; i += 1) {
    totalWorkExArray[i] = new Array(12);
  }
  experience.forEach((element_) => {
    const element = { ...element_ };
    if (element.currently_working || !element.end_date_month) {
      element.end_date_year = CURRENT_YEAR;
      element.end_date_month = CURRENT_MONTH;
    }
    if (element.currently_working || !element.end_date_year) {
      endYear = CURRENT_YEAR;
      endMonth = CURRENT_MONTH;
    } else {
      endYear = element.end_date_year;
      endMonth = element.end_date_month - 1;
    }

    for (
      let i = element.start_date_year - earliestYear;
      i < endYear - earliestYear + 1;
      i += 1
    ) {
      if (
        i === element.start_date_year - earliestYear &&
        i === endYear - earliestYear
      ) {
        startMonth = element.start_date_month - 1;
        endMonth = element.end_date_month - 1;
      } else if (i === element.start_date_year - earliestYear) {
        endMonth = 11;
        startMonth = element.start_date_month - 1;
      } else if (
        element.currently_working === 1 &&
        i === endYear - earliestYear
      ) {
        startMonth = 0;
        endMonth = CURRENT_MONTH;
      } else if (i === endYear - earliestYear) {
        startMonth = 0;
        endMonth = element.end_date_month - 1;
      } else {
        startMonth = 0;
        endMonth = 11;
      }
      for (let j = startMonth; j <= endMonth; j += 1) {
        if (!totalWorkExArray[i][j]) {
          count += 1;
        }
        totalWorkExArray[i][j] = 1;
      }
    }
  });

  const years = parseInt(count / 12, 10);
  const months = parseInt(count % 12, 10);
  let str = "";
  if (years) {
    if (years === 1) str += `${years} Year`;
    else str += `${years} Years`;
  }
  if (years && months) str += " ";
  if (months) {
    if (months === 1) str += `${months} Month`;
    else str += `${months} Months `;
  }

  return str;
};

export const convertToFeet = (n) => {
  const realFeet = (n * 0.3937) / 12;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return inches > 0 ? `${feet}’ ${inches}”` : `${feet}’`;
};

export const getFeedbackCountText = (feedbackCount, selfFeedback) =>
  `${
    feedbackCount === 1 && selfFeedback
      ? "Self Feedback."
      : feedbackCount === 1
      ? "1 feedback."
      : `${feedbackCount} feedbacks.`
  }`;
