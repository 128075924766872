import React from "react";
import PropTypes from "prop-types";
import ohNoModalImg from "@/assets/illustrations/ohNoModalImg.svg";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import socialLimitExhaustBg from "@/assets/illustrations/socialLimitExhaustBg.svg";
import styles from "./LimitExhaustModal.module.scss";

export default function LimitExhaustModal({ type, isVisible, hideModal }) {
  const history = useHistory();
  return (
    <ReactModal
      isOpen={isVisible}
      onRequestClose={hideModal}
      className="postJob__completeBanner-modal postJob__completeBanner-modal-2"
      overlayClassName="postJob__completeBanner-overlay-2"
    >
      <div
        className={styles.container}
        style={{ backgroundImage: `url(${socialLimitExhaustBg})` }}
      >
        <div className={styles.title}>Oops...</div>
        <div className={styles.subtitle}>You have Reached Your Limit</div>
        <div className={styles.typeContainer}>
          <div className={styles.typeTitle}>Upgrade to Premium</div>
          <div className={styles.typeSubtitle}>
            To{" "}
            {type === "EXPERIENCE"
              ? " Post More Experiences!"
              : " increase Connects Limit!"}
          </div>
          <div className={styles.buttonContainer}>
            <img src={ohNoModalImg} alt="" className={styles.buttonImg} />
            <div className={styles.fakeButton}>View Plans</div>
            <div
              className={styles.realBtn}
              onClick={() => history.push("/premium")}
            />
          </div>
          {type === "CONNECT" && (
            <div className={styles.extraMsg}>
              or
              <br />
              Come Back Tomorrow
            </div>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

LimitExhaustModal.propTypes = {
  type: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};
