import axios from "axios";
// import config from "@/config/appConfig";
import { mainAxiosInstance } from "./networkAdapters/axiosInstance";

// eslint-disable-next-line import/prefer-default-export
export const getPlacesPrediction = (val) =>
  new Promise((resolve, reject) => {
    mainAxiosInstance
      .get(`/utilities/places_autocomplete?query=${val}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        console.log("Error in getPlacesPrediction api ", err.response);
        reject(err);
      });
  });

export const getCityFromCoordinates = (lat, long) =>
  new Promise((resolve, reject) => {
    const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${long}&localityLanguage=en`;
    axios
      .get(url)
      .then((res) => {
        resolve(
          res.data?.locality
            ? `${res.data.locality}, ${res.data.principalSubdivision}, ${res.data.countryName}`
            : ""
        );
      })
      .catch((err) => {
        console.log("Error in getCityFromCoordinates api ", err.response);
        reject(err);
      });
  });
