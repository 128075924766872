import { SET_NOTIFICATION_COUNT_DATA } from "../CONSTANTS";

const initialState = {
  messageCount: 0,
  notificationCount: 0,
  notificationCountSocialServices: 0,
  newCandidateApplyCount: 0,
  socialServicesChatCount: 0,
  newOfferReceivedCount: 0,
};
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION_COUNT_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default notificationReducer;
