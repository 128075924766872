/* eslint-disable no-unused-vars */
import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import moment from "moment";
import Lottie from "react-lottie";
import ResumeUploadImg from "@/assets/svg/resumeUpload.svg";
import uploadDone from "@/assets/gifs/uploadDone.gif";
import { mainAxiosInstance as Axios } from "@/services/networkAdapters/axiosInstance";
import { showToastMessage } from "@/components/ToastComponent";
import styles from "./ResumeUploder.module.scss";
import animationData from "./animation.json";


const ResumeUploader = forwardRef(
  (
    {
      restoredData,
      onUploadSuccess = () => { },
      showApplyButton = false,
      type = "AS_IS",
      readOnly,
      onActionButtonClick,
      extraParamsToSend = {}
    },
    ref
  ) => {
    const [resumeData, setResumeData] = useState({
      name: "",
      fileType: "",
      link: "",
      originalFileName: ""
    });
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);

    const fileUploader = useRef(null);
    useImperativeHandle(ref, () => ({
      getFileUploader: () => fileUploader.current
    }));
    const chooseFile = () => {
      fileUploader.current.click();
    };
    const uploadResume = async (resume) => {
      const data = new FormData();
      data.append("file", resume);
      const keys = Object.keys(extraParamsToSend);

      for (let i = 0; i < keys.length; i += 1) {
        data.append(keys[i], extraParamsToSend[keys[i]]);
      }
      Axios.post("/profile/upload_resume", data)

        .then((res) => {
          console.log(res.data);
          const resumeData_ = {
            name: res.data.data.name || resume?.name,
            fileType: res.data.data.fileType,
            link: res.data.data.link,
            originalFileName: res.data.data.originalFileName || resume?.name
          };
          setResumeData(resumeData_);
          setError("");
          onUploadSuccess(resumeData_);
          showToastMessage("info", res.data.message);
        })
        .catch((err) => {
          if (err?.response?.data?.message)
          showToastMessage("info",err?.response?.data?.message);
            setError(err?.response?.data?.message);
          const fileType = resume.type === "application/pdf" ? "PDF" : "DOC";
          setResumeData({
            name: resume?.name,
            fileType,
            link: "res.data.data.link"
          });
        })
        .finally(() => {
          setFile(resume);
        });
    };
    const attachFile = async (evt) => {
      const resumeFile = evt.target.files[0];
      if (resumeFile) {
        fileUploader.current.value = null;
      }

      uploadResume(resumeFile);
    };
    useEffect(() => {
      console.log(restoredData);
      if (restoredData) {
        setResumeData(restoredData);
        onUploadSuccess(restoredData, "restored");
      }
    }, [!!restoredData]);
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    return (
      <div className={styles.container}>
        {readOnly && !resumeData.link && (
          <div className={styles.lockedIcon}>
            <Icon />
          </div>
        )}
        {type === "PROFILE" ? (
          <div className={styles.mainContainer}>
            {resumeData?.link.length === 0 ? (
              <div className={styles.resumeNotUploadedContainer}>
                <div className={styles.textContainer}>
                  <div className={styles.resumeNotUploadedHeading}>Upload resume</div>
                  <div className={styles.resumeNotUploadedSubHeading}>
                    Update your resume to help recruiters to know more about you.
                  </div>
                  <label htmlFor="file_resume" className={styles.uploadButtonNew}>
                    Upload
                  </label>
                </div>
              </div>
            ) : (
              <div>
                <div className={styles.resumeText}>Resume</div>
                <div className={styles.resumeUploadedContainer}>
                  <div className={styles.textContainerUploaded}>
                    <div className={styles.resumeUploadedHeading}>Your resume</div>
                    <div className={styles.resumeUploadedName}>
                      {resumeData.originalFileName}
                      <span
                        className={styles.resumeUploadedView}
                        onClick={(e) => {
                          if (!readOnly && resumeData?.link) {
                            window.open(resumeData?.link, "_blank").focus();
                          }
                        }}
                      >
                        View
                      </span>
                    </div>
                  </div>
                  <label htmlFor="file_resume" className={styles.uploadAgain}>
                    Upload again
                  </label>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {(file || resumeData.name) && (
              <div
                className={`${styles.uploadItem} ${
                  error ? styles.uploadItemError : ""
                } ${readOnly && !resumeData.link ? styles.uploadItemLocked : ""}`}
                style={readOnly ? { cursor: "pointer" } : null}
                onClick={(e) => {
                  if (readOnly && resumeData.link) {
                    window.open(resumeData.link, "_blank").focus();
                  }
                }}
              >
                <div className={styles.uploadItemField}>
                  <div className={styles.uploadItemLabel}>
                    {type === "GENERATED_RESUME_LETTER" ? (
                      <GeneratedResumeIcon />
                    ) : type === "GENERATED_COVER_LETTER" ? (
                      <GeneratedCVLetter />
                    ) : (
                      <img src={uploadDone} className={styles.uploadDone} alt="" />
                    )}
                  </div>
                  <div
                    className={styles.uploadItemValue}
                    style={readOnly ? { width: "100%" } : { cursor: "pointer" }}
                    onClick={(e) => {
                      if (!readOnly && resumeData.link) {
                        window.open(resumeData.link, "_blank").focus();
                      }
                    }}
                  >
                    {type === "GENERATED_RESUME_LETTER" ? (
                      <div style={{ color: "#297bca" }}>Your Generated Resume</div>
                    ) : type === "GENERATED_COVER_LETTER" ? (
                      <div style={{ color: "#297bca" }}>Your Generated Cover letter</div>
                    ) : (
                      <div>{resumeData.name}</div>
                    )}
                    {resumeData.lastUpdatedOn && !readOnly && (
                      <div className={styles.uploadItemUpdatedOn}>
                        Last Updated On{" "}
                        {moment(new Date(resumeData.lastUpdatedOn)).format(
                          "DD/MM/YYYY"
                        )}
                      </div>
                    )}
                  </div>
                  {resumeData.lastUpdatedOn && !readOnly && (
                    <div>
                      <label
                        htmlFor="file_resume"
                        style={{
                          cursor: "pointer",
                          color: "#297bca",
                          textDecoration: "underline",
                          fontSize: "13px",
                        }}
                      >
                        Change
                      </label>
                    </div>
                  )}
                </div>
              </div>
            )}
            {showApplyButton && resumeData.name && !error ? (
              <button className={styles.actionButton} onClick={onActionButtonClick}>
                Done
              </button>
            ) : type === "MINIMAL" ||
              type === "GENERATED_RESUME_LETTER" ||
              type === "GENERATED_COVER_LETTER" ? (
              resumeData.name ? null : (
                <div className={styles.uploadItemAlternate} onClick={chooseFile}>
                  <div className={styles.uploadItemAlternateText}>Upload Resume</div>
                  <div className={styles.uploadItemAlternateButton}>
                    <UploadIcon />
                  </div>
                </div>
              )
            ) : (
              (!resumeData.name || error) && (
                <label htmlFor="file_resume" className={styles.uploadButton}>
                  <UploadIcon /> Browse & upload your resume
                </label>
              )
            )}
    
            {error ? <div className={styles.uploadButtonError}>{error}</div> : ""}
          </div>
        )}
        <input
          type="file"
          style={{
            display: "none",
          }}
          ref={fileUploader}
          id="file_resume"
          name=""
          accept=".pdf,.doc,.docx"
          onChange={attachFile}
        />
      </div>
    );
    
  }
);
export default ResumeUploader;

const UploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
    <path d="M14.6392 12.8177L11.0032 9.18164L7.36719 12.8177" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.0078 9.18164V17.3627" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.6305 14.9874C19.5171 14.504 20.2175 13.7392 20.6211 12.8136C21.0247 11.888 21.1086 10.8543 20.8596 9.87576C20.6105 8.89717 20.0426 8.0294 19.2456 7.4094C18.4486 6.7894 17.4677 6.45248 16.458 6.45181H15.3126C15.0375 5.38758 14.5247 4.39958 13.8127 3.56207C13.1008 2.72456 12.2082 2.05935 11.2022 1.61644C10.1961 1.17354 9.10278 0.964459 8.00431 1.00493C6.90583 1.0454 5.83082 1.33437 4.86011 1.85011C3.88939 2.36585 3.04821 3.09494 2.39983 3.98257C1.75144 4.87019 1.31272 5.89326 1.11664 6.97485C0.920554 8.05644 0.972218 9.1684 1.26774 10.2272C1.56327 11.2859 2.09497 12.2639 2.82286 13.0876" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.6392 12.8177L11.0032 9.18164L7.36719 12.8177" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Icon = () => (
  <svg
    width="31"
    height="37"
    viewBox="0 0 31 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8424 16.5797C23.9846 16.5797 25.0777 16.7429 26.1056 17.0529V14.9481C26.1056 13.1533 24.6372 11.6849 22.8424 11.6849H21.2108V8.42168C21.2108 3.91846 17.556 0.263672 13.0528 0.263672C8.5496 0.263672 4.89481 3.91846 4.89481 8.42168V11.6849H3.2632C1.46844 11.6849 0 13.1533 0 14.9481V31.2641C0 33.0589 1.46844 34.5273 3.2632 34.5273H13.477C12.2819 32.8159 11.579 30.8096 11.4448 28.7265C11.3106 26.6434 11.7503 24.5634 12.7159 22.7128C13.6816 20.8622 15.1363 19.3119 16.9218 18.2306C18.7072 17.1492 20.755 16.5782 22.8424 16.5797ZM7.99485 8.42168C7.99485 5.63164 10.2628 3.36372 13.0528 3.36372C15.8429 3.36372 18.1108 5.63164 18.1108 8.42168V11.6849H7.99485V8.42168Z"
      fill="#297bca"
      fillOpacity="0.8"
    />
    <path
      d="M22.8416 19.8428C18.3384 19.8428 14.6836 23.4976 14.6836 28.0008C14.6836 32.504 18.3384 36.1588 22.8416 36.1588C27.3448 36.1588 30.9996 32.504 30.9996 28.0008C30.9996 23.4976 27.3448 19.8428 22.8416 19.8428ZM22.8416 23.106C24.1958 23.106 25.289 24.1992 25.289 25.5534C25.289 26.9076 24.1958 28.0008 22.8416 28.0008C21.4874 28.0008 20.3942 26.9076 20.3942 25.5534C20.3942 24.1992 21.4874 23.106 22.8416 23.106ZM22.8416 32.8956C21.1611 32.8956 19.6763 32.0472 18.7952 30.7419C19.9863 30.0566 21.3568 29.6324 22.8416 29.6324C24.3264 29.6324 25.6969 30.0566 26.888 30.7419C26.0069 32.0472 24.5222 32.8956 22.8416 32.8956Z"
      fill="#297bca"
      fillOpacity="0.8"
    />
  </svg>
);

const GeneratedResumeIcon = () => (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 8.75H18.125L11.25 1.875V8.75ZM2.5 0H12.5L20 7.5V22.5C20 23.163 19.7366 23.7989 19.2678 24.2678C18.7989 24.7366 18.163 25 17.5 25H2.5C1.83696 25 1.20107 24.7366 0.732233 24.2678C0.263392 23.7989 0 23.163 0 22.5V2.5C0 1.1125 1.1125 0 2.5 0ZM12.5 22.5V21.25C12.5 19.5875 9.1625 18.75 7.5 18.75C5.8375 18.75 2.5 19.5875 2.5 21.25V22.5H12.5ZM7.5 12.5C6.83696 12.5 6.20107 12.7634 5.73223 13.2322C5.26339 13.7011 5 14.337 5 15C5 15.663 5.26339 16.2989 5.73223 16.7678C6.20107 17.2366 6.83696 17.5 7.5 17.5C8.16304 17.5 8.79893 17.2366 9.26777 16.7678C9.73661 16.2989 10 15.663 10 15C10 14.337 9.73661 13.7011 9.26777 13.2322C8.79893 12.7634 8.16304 12.5 7.5 12.5Z"
      fill="#FAFAFA"
    />
  </svg>
);

const GeneratedCVLetter = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 9.33333H22L14.6667 2V9.33333ZM2.66667 0H16L24 8V21.3333C24 22.0406 23.719 22.7189 23.219 23.219C22.7189 23.719 22.0406 24 21.3333 24H2.66667C1.95942 24 1.28115 23.719 0.781048 23.219C0.280951 22.7189 0 22.0406 0 21.3333V2.66667C0 1.18667 1.18667 0 2.66667 0ZM2.66667 12V14.6667H21.3333V12H2.66667ZM2.66667 17.3333V20H14.6667V17.3333H2.66667Z"
      fill="#FAFAFA"
    />
  </svg>
);


