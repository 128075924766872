/* eslint-disable no-unused-vars */
import React from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren
} from "react-circular-progressbar";
import styles from "./PitchCard.module.scss";

const getColorForAvailability = (val) => {
  if (val === "Currently Unavailable") return "#d20000";
  if (val === "Available Immediately") return "#00d285";
  return "#ffb82b";
};
export default function PitchCard({ data }) {
  return (
    <div className={styles.container}>
      <div className={styles.userDetailsContainer}>
        <div className={styles.userDetailsLeft}>
          <div className={styles.userPfpContainer}>
            <CircularProgressbarWithChildren
              value={80}
              circleRatio={0.85}
              strokeWidth={3.8}
              styles={buildStyles({
                rotation: 0.4,
                pathColor: "#389fff"
              })}
            >
              <div className={styles.userPfp}>
                <img src={data.imageId} alt="" />
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
        <div className={styles.userDetailsRight}>
          <div className={styles.userName}>{data.name}</div>
          <div className={styles.userLocation}> {data.location}</div>
          {/* <div
            className={styles.userAvailability}
            style={{
              color: getColorForAvailability(data.currentAvailability),
            }}
          >
            {data.currentAvailability}
          </div> */}
        </div>
      </div>
      <div className={styles.pitchDetails}>
        {data.userEntrepreneurOffer && (
          <>
            <div className={styles.label}>Their Offer</div>
            <div className={styles.value}>{data.userEntrepreneurOffer}</div>
          </>
        )}
        {data.servicesOffered && (
          <>
            <div className={styles.label}>Service Offered</div>
            <div className={styles.value}>{data.servicesOffered}</div>
          </>
        )}
        {data.whatSetsThemApart && (
          <>
            <div className={styles.label}>What Sets Them Apart</div>
            <div className={styles.value}>{data.whatSetsThemApart}</div>
          </>
        )}
        {data.priorExperience && (
          <>
            <div className={styles.label}>Prior Experience</div>
            <div className={styles.sectionValue}>{data.priorExperience}</div>
          </>
        )}
        {data.additionalInformation && (
          <>
            <div className={styles.label}>Additional Information</div>
            <div className={styles.sectionValue}>
              {data.additionalInformation}
            </div>
          </>
        )}
        <>
          <div className={styles.label}>Skills</div>
          <div className={styles.cards}>
            {data.skills.map((item) => (
              <div className={styles.cardItem}>{item.skill}</div>
            ))}
          </div>
        </>
        {data.jobType && (
          <>
            <div className={styles.label}>Mode</div>
            <div className={styles.cards}>
              <div className={styles.cardItem}>{data.jobType}</div>
            </div>
          </>
        )}
        {data.monthly_compensation_id && (
          <>
            <div className={styles.label}>Expected Compensation</div>
            <div className={styles.cards}>
              <div className={styles.cardItem}>{data.displayCompensation}</div>
            </div>
          </>
        )}
        {data.hourly_compensation_id && (
          <>
            <div className={styles.label}>Hourly Compensation</div>
            <div className={styles.cards}>
              <div className={styles.cardItem}>{data.displayCompensation}</div>
            </div>
          </>
        )}
        {data.entrepreneur_earning_potential_id && (
          <>
            {" "}
            <div className={styles.label}>Earning Potential</div>
            <div className={styles.cards}>
              <div className={styles.cardItem}>{data.displayCompensation}</div>
            </div>
          </>
        )}
        {data.entrepreneur_investment_potential_id && (
          <>
            <div className={styles.label}>Investment Potential</div>
            <div className={styles.cards}>
              <div className={styles.cardItem}>
                {data.entrepreneur_investment_potential}
              </div>
            </div>
          </>
        )}
        {data.entrepreneur_work_type_id && (
          <>
            {" "}
            <div className={styles.label}>Time</div>
            <div className={styles.cards}>
              <div className={styles.cardItem}>
                {data.entrepreneur_work_type}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
