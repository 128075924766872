import { LOAD_INTRO_DATA, LOGOUT } from "../CONSTANTS";

const initialState = sessionStorage.getItem("createIntroDataForRestoring")
  ? JSON.parse(sessionStorage.getItem("createIntroDataForRestoring"))
  : {
      introId: 0,
      introDetails: null,
    };
const createIntroReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INTRO_DATA:
      if (action.payload.introId) {
        sessionStorage.setItem(
          "createIntroDataForRestoring",
          JSON.stringify(action.payload)
        );
      } else sessionStorage.removeItem("createIntroDataForRestoring");
      return { ...action.payload };
    case LOGOUT:
      sessionStorage.removeItem("createIntroDataForRestoring");
      return {
        introId: 0,
        introDetails: null,
      };
    default:
      return state;
  }
};
export default createIntroReducer;
