/* eslint-disable react/prop-types */
import React from "react";
import logoIcon from "@/assets/logo/logo.svg";
import "./FullLogo.scss";

export default function FullLogo({ history, type }) {
  return (
    <div className="fullLogo__container" onClick={() => history.push("/")}>
      <img src={logoIcon} alt="" className="fullLogo__icon" />
      <div className="fullLogo__text">
        <div className="fullLogo__text-1">goodspace</div>
        <div className="fullLogo__text-2">
          {type !== "SOCIAL"
            ? "Find work and hire instantly"
            : "Find Friends and Create Experiences"}
        </div>
      </div>
    </div>
  );
}
