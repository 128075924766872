import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { showToastMessage } from "@/components/ToastComponent";
import styles from "./JobInterviewModal.module.scss";

export default function JobInterviewModal({
  isOpen,
  hideModal,
  interviewLink,
   interviewLinkLocal
}) {
  const [copy, setCopy] = React.useState(false);
  const [interviewLater, setInterviewLater] = React.useState(false);
  const userInfo = useSelector((state) => state.user);
  const copyLink = async () => {
    try {
      const inp = document.createElement("input");
      inp.value = interviewLink;
      inp.select();
      setCopy(true);
      inp.setSelectionRange(0, 99999);
      await navigator.clipboard.writeText(inp.value);
      showToastMessage("info", "Link copied to clipboard.");
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (copy)
      setTimeout(() => {
        setCopy(false);
      }, 4000);
  }, [copy]);
  return (
    <ReactModal
      isOpen={isOpen}
      className="postJob__completeBanner-modal"
      overlayClassName="postJob__completeBanner-overlay-2"
    >
      <div className={styles.autoInterviewActivatedModal}>
        <div className={styles.modalCloseButton} onClick={hideModal}>
          <CrossIcon />
        </div>
        <SuccessCheckIcon />
        <div className={styles.autoInterviewActivatedModalTitle}>
          Congratulations {userInfo?.name?.split(" ")[0]}!
        </div>
        <div className={styles.autoInterviewActivatedModalSubTitle}>
          You have been shorlisted by the GoodSpace AI
        </div>
        <Link
          to={interviewLinkLocal?.replace("https://ourgoodspace.com", "")}
          className={styles.autoInterviewActivatedModalButton}
          onClick={hideModal}
        >
          Take Interview Now
        </Link>
        <div className={styles.autoInterviewActivatedModalText}>OR</div>
        {interviewLater ? (
          <div className={styles.linkSuperContainer}>
            <div className={styles.linkContainer}>
              <div className={styles.link}>{interviewLink}</div>
              <div className={styles.linkCopyButton} onClick={copyLink}>
                <CopyIcon /> {!copy ? "Copy" : "Copied"}
              </div>
            </div>
            <div className={styles.linkMessage}>
              You can copy the link and take interview anytime within the next
              24 hours
            </div>
          </div>
        ) : (
          <button
            className={styles.autoInterviewActivatedModalButton}
            onClick={() => setInterviewLater(true)}
          >
            Take Interview Later
          </button>
        )}
      </div>
    </ReactModal>
  );
}

const SuccessCheckIcon = () => (
  <svg viewBox="0 0 98 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6492_3718)">
      <circle
        cx="53.9473"
        cy="34.3769"
        r="32.623"
        fill="#389FFF"
        fillOpacity="0.13"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter1_d_6492_3718)">
      <circle cx="54.1056" cy="34.2188" r="28.6446" fill="#389FFF" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.4976 23.2852C70.195 23.9831 70.195 25.1148 69.4976 25.8127L49.853 45.4722C49.1556 46.1702 48.0248 46.1702 47.3274 45.4722L38.3981 36.5361C37.7006 35.8381 37.7006 34.7065 38.3981 34.0086C39.0955 33.3106 40.2262 33.3106 40.9237 34.0086L48.5902 41.6809L66.972 23.2852C67.6694 22.5872 68.8001 22.5872 69.4976 23.2852Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.7008 56.4292C88.832 56.5535 88.832 56.755 88.7008 56.8793L85.0052 60.3803C84.874 60.5046 84.6613 60.5046 84.5301 60.3803L82.8504 58.7889C82.7192 58.6646 82.7192 58.4631 82.8504 58.3388C82.9816 58.2145 83.1943 58.2145 83.3255 58.3388L84.7677 59.7051L88.2256 56.4292C88.3568 56.3049 88.5696 56.3049 88.7008 56.4292Z"
      fill="#2A62FF"
    />
    <circle cx="17.8236" cy="56.0209" r="2.22791" fill="#2A62FF" />
    <circle cx="30.6733" cy="1.90964" r="1.90964" fill="#2A62FF" />
    <path
      d="M3.29636 27.5856C3.23223 27.3905 3.02204 27.2843 2.82688 27.3484C2.63172 27.4125 2.52551 27.6227 2.58964 27.8179L3.28634 29.938L1.16618 30.6347C0.971029 30.6989 0.864812 30.9091 0.928942 31.1042C0.993072 31.2994 1.20326 31.4056 1.39842 31.3415L3.51858 30.6448L4.21529 32.7649C4.27942 32.9601 4.48961 33.0663 4.68476 33.0022C4.87992 32.938 4.98614 32.7278 4.92201 32.5327L4.2253 30.4125L6.34546 29.7158C6.54062 29.6517 6.64683 29.4415 6.5827 29.2463C6.51857 29.0512 6.30838 28.945 6.11323 29.0091L3.99306 29.7058L3.29636 27.5856Z"
      fill="#2A62FF"
    />
    <path
      d="M95.1714 13.8024C95.2571 13.6324 95.1888 13.4251 95.0188 13.3394C94.8488 13.2537 94.6415 13.322 94.5558 13.492L93.6245 15.3386L91.7779 14.4073C91.6079 14.3215 91.4006 14.3899 91.3149 14.5598C91.2292 14.7298 91.2975 14.9371 91.4674 15.0228L93.3141 15.9541L92.3828 17.8008C92.297 17.9707 92.3653 18.178 92.5353 18.2637C92.7053 18.3495 92.9126 18.2812 92.9983 18.1112L93.9296 16.2646L95.7762 17.1959C95.9462 17.2816 96.1535 17.2133 96.2392 17.0433C96.325 16.8734 96.2567 16.6661 96.0867 16.5803L94.2401 15.649L95.1714 13.8024Z"
      fill="#2A62FF"
    />
    <defs>
      <filter
        id="filter0_d_6492_3718"
        x="17.3242"
        y="1.75391"
        width="73.2461"
        height="73.2461"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.219608 0 0 0 0 0.623529 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6492_3718"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6492_3718"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_6492_3718"
        x="21.4609"
        y="5.57422"
        width="65.2891"
        height="65.2891"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.219608 0 0 0 0 0.623529 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_6492_3718"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_6492_3718"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const CopyIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6491_4479)">
      <path
        d="M15.5798 0.97677C14.1377 0.964239 12.7484 1.51861 11.7111 2.52048L11.7016 2.52976L10.1249 4.09726C9.76591 4.45419 9.76422 5.03459 10.1212 5.39361C10.4781 5.75264 11.0585 5.75433 11.4175 5.39739L12.9892 3.83482C13.6803 3.16965 14.6045 2.8017 15.5638 2.81003C16.5252 2.81839 17.4449 3.20401 18.1247 3.88384C18.8045 4.56367 19.1902 5.48331 19.1985 6.4447C19.2068 7.40358 18.8392 8.32747 18.1746 9.01842L15.4305 11.7625C15.0588 12.1343 14.6114 12.422 14.1187 12.6058C13.6261 12.7895 13.0997 12.8652 12.5753 12.8276C12.0509 12.7901 11.5406 12.6402 11.0792 12.3881C10.6178 12.136 10.216 11.7876 9.90109 11.3666C9.59785 10.9612 9.02339 10.8784 8.61799 11.1816C8.21259 11.4849 8.12978 12.0593 8.43302 12.4647C8.90542 13.0963 9.50811 13.6188 10.2002 13.997C10.8923 14.3751 11.6577 14.6 12.4443 14.6563C13.231 14.7126 14.0206 14.5991 14.7595 14.3235C15.4984 14.0478 16.1694 13.6165 16.727 13.0587L19.4769 10.3088L19.4881 10.2975C20.4899 9.26017 21.0443 7.87085 21.0318 6.42877C21.0192 4.98669 20.4408 3.60722 19.4211 2.58748C18.4013 1.56773 17.0219 0.989302 15.5798 0.97677Z"
        fill="#FFA800"
      />
      <path
        d="M9.5564 7.34169C8.76974 7.28535 7.98017 7.39885 7.24123 7.6745C6.50235 7.95012 5.83138 8.38142 5.27383 8.93914L2.52383 11.6891L2.51267 11.7005C1.5108 12.7378 0.956427 14.1271 0.968958 15.5692C0.981489 17.0113 1.55992 18.3908 2.57966 19.4105C3.59941 20.4302 4.97888 21.0087 6.42096 21.0212C7.86304 21.0337 9.25235 20.4794 10.2897 19.4775L10.301 19.4663L11.8685 17.8988C12.2265 17.5409 12.2265 16.9605 11.8685 16.6025C11.5105 16.2445 10.9301 16.2445 10.5722 16.6025L9.0106 18.164C8.31964 18.8286 7.39576 19.1963 6.43689 19.1879C5.4755 19.1796 4.55586 18.794 3.87603 18.1141C3.1962 17.4343 2.81058 16.5147 2.80222 15.5533C2.79389 14.5944 3.16152 13.6705 3.82614 12.9796L6.57019 10.2355C6.94191 9.86365 7.38937 9.57597 7.88199 9.39221C8.37462 9.20845 8.901 9.13278 9.42544 9.17034C9.94987 9.2079 10.4601 9.35781 10.9215 9.60989C11.3829 9.86198 11.7847 10.2104 12.0996 10.6314C12.4029 11.0368 12.9773 11.1196 13.3827 10.8164C13.7881 10.5131 13.871 9.93865 13.5677 9.53325C13.0953 8.90171 12.4926 8.37915 11.8005 8.00102C11.1084 7.62289 10.3431 7.39803 9.5564 7.34169Z"
        fill="#FFA800"
      />
    </g>
    <defs>
      <clipPath id="clip0_6491_4479">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const CrossIcon = () => (
  <svg viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
        fill="#000"
      />{" "}
    </g>
  </svg>
);
