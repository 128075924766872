/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import config from "@/config/appConfig";
import userReducer from "./reducers/userReducer";
import hireFilterReducer from "./reducers/hireFilterReducer";
import jobFilterReducer from "./reducers/jobFilterReducer";
import staticConfigReducer from "./reducers/staticConfigReducer";
import specificConfigReducer from "./reducers/specificConfigReducer";
import notificationReducer from "./reducers/notificationReducer";
import globalStateReducer from "./reducers/globalStateReducer";
import createPitchReducer from "./reducers/createPitchReducer";
import createIntroReducer from "./reducers/createIntroReducer";

const NODE_ENV = config.nodeEnv;
const rootReducer = combineReducers({
  user: userReducer,
  jobPageData: jobFilterReducer,
  hirePageData: hireFilterReducer,
  staticConfig: staticConfigReducer,
  specificConfig: specificConfigReducer,
  notificationData: notificationReducer,
  globalState: globalStateReducer,
  createPitchData: createPitchReducer,
  createIntroData: createIntroReducer
});
const middlewares = [thunk];
const devTools =
  NODE_ENV === "DEVELOPMENT" && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    : (a) => a;

const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middlewares), devTools)
);

export default store;
