import { useEffect, useRef, useState } from "react";
// import { `interviewAxiosInstance `} from "@/services/networkAdapters/axiosInstance";
import audioBufferToWav from "./audioFunctions";
import { timeSince } from "./dateTimeFunctions";

// eslint-disable-next-line import/prefer-default-export
export const useEffectNotOnMount = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};
export const useCustomMessageTimeHook = (date) => {
  const [relativeFormattedTime, setRelativeFormattedTime] = useState(
    timeSince(new Date(date))
  );
  let interval;
  useEffect(() => {
    if (!relativeFormattedTime.thresholdCompleted)
      interval = setInterval(() => {
        const newRelativeTime = timeSince(new Date(date));
        if (newRelativeTime.time !== relativeFormattedTime.time)
          setRelativeFormattedTime(timeSince(new Date(date)));
      }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    if (relativeFormattedTime.thresholdCompleted) clearInterval(interval);
  }, [relativeFormattedTime.thresholdCompleted]);

  return relativeFormattedTime;
};

export const useEventListener = (eventType, callback, element = window) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (element == null) return null;
    const handler = (e) => callbackRef.current(e);
    element.addEventListener(eventType, handler);

    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element]);
};
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEventListener("resize", () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  });

  return windowSize;
};

export const useScreenIsXl = () => {
  const [screenIsXl, setScreenIsXl] = useState(window.innerWidth > 991);

  useEventListener("resize", () => {
    if (window.innerWidth <= 991 && screenIsXl) {
      setScreenIsXl(false);
    } else if (window.innerWidth > 991 && !screenIsXl) {
      setScreenIsXl(true);
    }
  });

  return screenIsXl;
};

export const useScreenIsBiggerThan = (size) => {
  const [state, setState] = useState(window.innerWidth > size);

  useEventListener("resize", () => {
    if (window.innerWidth <= size && state) {
      setState(false);
    } else if (window.innerWidth > size && !state) {
      setState(true);
    }
  });

  return state;
};
export const useStreamingAudioRecorder = ({ onDataAvailable }) => {
  // const [savedAudioChunks, setSavedAudioChunks] = useState([]);
  const [stream, setStream] = useState(null);
  const recordingStatusRef = useRef("stopped");
  // const [mediaRecorder, setMediaRecorder] = useState(null);
  const processor = useRef();
  // const mediaStream = useRef();
  const [recordingStatus, setRecordingStatus] = useState("stopped");
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  // const sampleRate = 16000;
  const getMediaPermissions = async () => {
    try {
      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });

      setStream(streamData);
    } catch (err) {
      setRecordingStatus("error");
    }
  };
  const startRecording = () => {
    console.log("recording");
    recordingStatusRef.current = "recording";
    setRecordingStatus("recording");
    processor.current = audioContext.createScriptProcessor(1024, 1, 1);
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(processor.current);
    processor.current.connect(audioContext.destination);
    // console.log("connecting stream");
    processor.current.onaudioprocess = (event) => {
      // console.log("getting stream data");
      const audioData = event.inputBuffer.getChannelData(0);
      // console.log(recordingStatusRef.current);
      if (recordingStatusRef.current === "recording") {
        if (typeof onDataAvailable === "function") {
          onDataAvailable(Float32Array.from(audioData));
          // console.log(audioBlobInPCM);
          // onDataAvailable(audioBlobInPCM);
        }
        // socket.emit("audio_chunk", Float32Array.from(audioData));
      }
    };
  };
  const stopRecording = () => {
    recordingStatusRef.current = "stopped";

    setRecordingStatus("stopped");
    if (processor.current) {
      console.log("stopping stream");
      processor.current.disconnect();
    }
    // mediaStream.current.stop();
    // mediaStream.current.onstop = () => {
    //   // console.log(savedAudioChunks);
    // };
  };

  const releaseMic = () => {
    if (stream) stream.getTracks().forEach((track) => track.stop());
  };
  useEffect(() => {
    getMediaPermissions();
    return () => {
      releaseMic();
    };
  }, []);
  return {
    recordingStatus,
    startRecording,
    stopRecording,
    releaseMic
  };
};
export const useAudioRecorder = ({ mimeType, onDataAvailable }) => {
  const [recordingStatus, setRecordingStatus] = useState(false);
  const [audio, setAudio] = useState({
    audioBlob: null,
    audioUrl: null
  });
  const [savedAudioChunks, setSavedAudioChunks] = useState([]);
  const [stream, setStream] = useState(null);
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

  const mediaStream = useRef();
  const getMediaPermissions = async () => {
    try {
      const streamData = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      });
      setStream(streamData);
    } catch (err) {
      setRecordingStatus("error");
    }
  };
  const startRecording = () => {
    const audioChunks = [];
    console.log("starting to record");
    setRecordingStatus("recording");
    const media = new MediaRecorder(stream, {
      mimeType,
      audioBitsPerSecond: 44100
    });
    mediaStream.current = media;
    mediaStream.current.start(1000);
    mediaStream.current.ondataavailable = async (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      console.log("data available");
      console.log(onDataAvailable);
      if (typeof onDataAvailable === "function") {
        console.log("streaming data");
        const blob = new Blob([event.data], {
          type: "audio/webm; codecs=opus"
        });
        // let arrayBuffer;
        const fileReader = new FileReader();
        fileReader.onload = function (e) {
          arrayBuffer = e.target.result;
        };
        fileReader.readAsArrayBuffer(blob);
        fileReader.onloadend = function () {
          audioCtx.decodeAudioData(
            fileReader.result,
            (buffer) => {
              const audioBlobInPCM = audioBufferToWav(buffer);
              console.log(audioBlobInPCM);
              onDataAvailable(audioBlobInPCM);
            },
            (e) => {
              console.log(e);
            }
          );
        };

        // console.log(audioBlobInPCM);
        // onDataAvailable(audioBlobInPCM);
      }
      // console.log(audioChunks);
      audioChunks.push(event.data);
    };
    setSavedAudioChunks(audioChunks);
  };
  const stopRecording = () => {
    setRecordingStatus("stopped");
    mediaStream.current.stop();
    mediaStream.current.onstop = () => {
      const audioBlob = new Blob(savedAudioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      setSavedAudioChunks([]);
      setAudio({
        audioBlob,
        audioUrl
      });
    };
  };
  const releaseMic = () => {
    if (stream) stream.getTracks().forEach((track) => track.stop());
  };
  const clearAudio = () => {
    setAudio({
      audioBlob: null,
      audioUrl: null
    });
  };
  useEffect(() => {
    getMediaPermissions();
    return () => {
      releaseMic();
    };
  }, []);
  return {
    audioFile: audio.audioBlob,
    audioUrl: audio.audioUrl,
    recordingStatus,
    startRecording,
    stopRecording,
    releaseMic,
    clearAudio
  };
};
export function useCountdown() {
  const [countdownValue, setCountdownValue] = useState(-1);

  useEffect(() => {
    let intervalId;

    if (countdownValue > 0) {
      intervalId = setInterval(() => {
        setCountdownValue((prevValue) => prevValue - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [countdownValue]);

  function start(countdownFrom) {
    setCountdownValue(countdownFrom);
  }

  return { start, countdownValue };
}

export const useReadMoreText = (text, lengthToShow) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText =
    text.length <= lengthToShow
      ? text
      : isExpanded
      ? text
      : `${text.substring(0, lengthToShow)}...`;

  return {
    isExpanded,
    needToShowReadMore: text.length > lengthToShow,
    toggleExpanded,
    displayText
  };
};
