import React, { Component } from "react";
import ErrorPage from "@/pages/ErrorPage";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to the console and potentially an external service
    console.error("ErrorBoundary caught an error", error, errorInfo);
    // Example: Log to an external service
    // Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
      
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
