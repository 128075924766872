import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { detect } from "detect-browser";
import config from "@/config/appConfig";
import store from "@/redux/store";

const browser = detect();

const socialInstance = axios.create({
  baseURL: config.socialBaseUrl,
  headers: {
    "device-id": uuidv4(),
    "device-type": "web",
  },
});
if (browser) {
  socialInstance.defaults.headers.userdevice = `${browser.name} ${browser.version} - ${browser.os}`;
}

socialInstance.interceptors.request.use((instanceConfig) => {
  const { token } = store.getState().user;
  // eslint-disable-next-line no-param-reassign
  instanceConfig.headers.Authorization = token;
  return instanceConfig;
});
export default socialInstance;
