import {
  SET_FILTER_BY_JOB,
  UPDATE_FILTER_MAIN_JOB,
  UPDATE_FILTER_OTHER_JOB,
  UPDATE_NAME_INPUT_VALUE_JOB,
  UPDATE_TOF_INPUT_VALUE_JOB,
  RESET_STATE_JOB,
  SET_PAGE_JOB,
  SET_LAST_PAGE_JOB,
  SET_FILTER_BY_OFFER_ID,
  SET_FILTER_CATEGORY,
} from "../CONSTANTS";

const initialState = {
  resetPage: true,
  offerId: null,
  pageNum: 1,
  lastPage: null,
  filterBy: "", // name, filter, type of work, id
  filterByNameData: "",
  filterByNameInputValue: "",
  filterByTypeOfWorkInputValue: "",
  filterByTypeOfWork: [],
  filterData: {
    filterCategory: "JOBS",
    inputs: {},
    radius: 0,
    isMonthSelected: true,
    jobTypeId: [],
    jobTitleSelected: [],
    industrySelected: [],
    skillSelected: [],
    remoteJob: true,
    expCategory: 0,
    compensationVal: "",
    traitsSelected: [],
    addedToCollection: false,
    purpose: [],
  },
};
const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_BY_JOB:
      return { ...state, resetPage: true, filterBy: action.payload };
    case SET_FILTER_CATEGORY:
      return {
        ...state,
        resetPage: true,
        filterData: {
          ...initialState.filterData,
          filterCategory: action.payload,
        },
      };

    case SET_FILTER_BY_OFFER_ID:
      return {
        ...state,
        resetPage: true,
        filterBy: "offer",
        offerId: action.payload,
        filterData: {
          ...state.filterData,
          inputs: [{ offerId: action.payload }],
        },
      };
    case UPDATE_FILTER_MAIN_JOB:
      return {
        // ...initialState,
        ...state,
        resetPage: true,
        filterBy: "filter",
        filterData: {
          ...state.filterData,
          ...action.payload,
        },
      };
    case UPDATE_FILTER_OTHER_JOB:
      if (action.payload.filterBy === "name")
        return {
          ...initialState,
          resetPage: true,
          filterBy: "name",
          ...action.payload.data,
        };
      return {
        ...state,
        resetPage: true,
        ...action.payload.data,
      };
    case UPDATE_NAME_INPUT_VALUE_JOB:
      return {
        ...state,
        resetPage: true,
        filterByNameInputValue: action.payload,
      };
    case UPDATE_TOF_INPUT_VALUE_JOB:
      return {
        ...state,
        resetPage: true,
        filterByTypeOfWorkInputValue: action.payload,
      };
    case RESET_STATE_JOB:
      return {
        ...initialState,
        resetPage: true,
        pageNum: action.payload ? 1 : state.pageNum,
        lastPage: action.payload ? null : state.pageNum,
      };
    case SET_PAGE_JOB:
      return {
        ...state,
        resetPage: false,
        pageNum: action.payload,
      };
    case SET_LAST_PAGE_JOB:
      return {
        ...state,
        lastPage: action.payload,
      };
    default:
      return state;
  }
};
export default filterReducer;
