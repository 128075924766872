/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useRef } from "react";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import "react-multi-carousel/lib/styles.css";
// import Navbar from "@/components/Navbar";
import ReactGA from "react-ga4";
import { isMobile } from "react-device-detect";
import countries2 from "@/utils/countries";
import {
  fetchUserIpDetailsApi,
  fetchStaticConfigApi
} from "@/services/utilitiesService";
import RouterConfig from "@/navigation";
import ActivatePitchModal from "@/pages/SelectHirersForPitch/ActivatePitchModal/ActivatePitchModal";
import ActivateIntroModal from "@/dashboards/Social/pages/SelectUsersForIntro/ActivateIntroModal/ActivateIntroModal";
import RouteTracking from "@/navigation/RouteTracking";
import PushPopHandler from "@/navigation/PushPopHandler";
import ChatFloatingComponent from "@/components/ChatFloatingComponent";
import { loadStaticConfig } from "@/redux/actions/staticConfigActions";
import Footer from "@/components/FooterV2";
// import ReferralCodeModal from "@/components/ReferralCodeModal";
import Chatbot from "@/components/Chatbot";
import { fetchUserBasicInfoApi } from "@/services/userAuthService";

// import { useLocation } from "react-router-dom";
import "@/utils/dropConsoles";
import "./App.scss";
import DownloadAppBanner from "@/components/DownloadAppBanner";
import LoaderLg from "@/components/LoaderLg";
// import Branch from "branch-sdk";
import OnboardingLayoutV2 from "@/layout/OnboardingLayoutV2";
import ToastComponent from "@/components/ToastComponent";
// import SocialIntroModal from "@/components/SocialIntroModal";
import ReferralCodeModal from "@/components/ReferralCodeModal";
import InsufficientGcoinsModal from "@/components/InsufficientGcoinsModal";
import UserModeSwitcher from "@/components/UserModeSwitcher";
import IntercomComponent from "./components/IntercomWidget";
import { setIpCountry } from "@/redux/actions/globalStateActions";

export default function App() {

  // const branchIdentitySet = useRef(false);
  const loggedInUser = useSelector((state) => state.user);
  // const [bannerIsOpen, setBannerIsOpen] = useState(false);
  const dispatch = useDispatch();
  // const location = useLocation();
  const fetchStaticConfig = async () => {
    try {
      const res = await fetchStaticConfigApi();
      dispatch(loadStaticConfig(res.data.data));
    } catch (err) {
      console.error(err);
    }
  };
  // const openBanner = () => {
  //   setBannerIsOpen(true);
  // };
  // const closeBanner = () => {
  //   setBannerIsOpen(false);
  // };
  const fetchUserIpCountry = async () => {
    try {
      const data = await fetchUserIpDetailsApi();
      const { countryCode } = data;
      const country = countries2.find((a) => a.isoCode === countryCode);
      dispatch(
        setIpCountry({
          loaded: true,
          isoCode: country.isoCode,
          name: country.name,
          flag: country.flag,
          dialCode: country.dialCode
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!window?.clarity) {
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
        t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        t.onload = () => {
          console.log("Clarity initialized successfully.");
        };
        t.onerror = () => {
          console.error("Failed to initialize Clarity.");
        };
      })(window, document, "clarity", "script", "jufrurv0t1");
    }
    if (loggedInUser?.user_id) {
      const currentUserId = loggedInUser.user_id ?? "0";
      if (window?.clarity) {
        window.clarity("set", "userId", currentUserId.toString());
        console.log("User ID set for Clarity!");
      } else {
        console.log("Clarity is not defined, unable to set user ID.");
      }
    }
  }, [loggedInUser.user_id]);

  useEffect(() => {
    // if (isMobile) {
    //   openBanner();
    // }
    fetchUserIpCountry();
    fetchStaticConfig();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search
    });

    // ReactGA.pageview(window.location.pathname + window.location.search);
    if (loggedInUser.token && !loggedInUser.user_id) {
      fetchUserBasicInfoApi(loggedInUser.token);
    }
  }, []);
  // useEffect(() => {
  //   if (!loggedInUser.user_id && branchIdentitySet.current) {
  //     branchIdentitySet.current = false;
  //     Branch.logout();
  //   }
  //   if (loggedInUser.user_id && !branchIdentitySet.current) {
  //     branchIdentitySet.current = true;
  //     console.priorityLog("settingIdentity");
  //     Branch.setIdentity(loggedInUser.user_id.toString(), (err, data) => {});
  //   }
  // }, [loggedInUser.user_id]);
  if (loggedInUser.loading) return <LoaderLg />;
  return (
    <>
      {/* <DownloadAppBanner bannerIsOpen={bannerIsOpen} hideBanner={closeBanner} /> */}
      {/* <ReferralCodeModal isOpen onRequestClose={() => {}} /> */}
      {/* {bannerIsOpen ? (
        <>
          <br /> <br />
        </>
      ) : (
        ""
      )} */}
      <RouterConfig />

      {/* <Chatbot /> */}
      <Footer />
      <UserModeSwitcher />
      <RouteTracking />
      <PushPopHandler />
      <ToastComponent />
      {/* <SocialIntroModal /> */}
      <InsufficientGcoinsModal />
      <ActivatePitchModal useRedux />
      {/* <ActivateIntroModal useRedux /> */}
      {/* <ChatFloatingComponent /> */}
      <IntercomComponent />
      {/* <OnboardingLayoutV2 /> */}
    </>
  );
}
