import React from "react";

export default function DropIcon2() {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8">
      <path
        d="M9.76 0.24V3.12L5.22 7.72L0.68 3.12V0.24L5.22 4.86L9.76 0.24Z"
        fill="#727680"
      />
    </svg>
  );
}
