import React, { useEffect } from "react";
import AnimatedLogo from "@/components/AnimatedLogo";

import styles from "./LoaderLg.module.css";

export default function Loader() {
  useEffect(() => {
    document.body.style.position = "fixed";
    return () => {
      document.body.style.position = "static";
    };
  }, []);
  return (
    <div className={styles.lgloader__container}>
      <div className={`${styles.container} ${styles.containerShow}`}>
        <AnimatedLogo />
      </div>
    </div>
  );
}
