/* eslint-disable no-unused-vars */
import io from "socket.io-client";
import { v4 as uuid } from "uuid";
import { removeUndefinedKeys } from "@/utils/utilityFunctions";
import store from "@/redux/store";
import config from "@/config/appConfig";

let socket;
let interval;

const websocketManager = {
  connect: (withUserId, type = "GENERAL") =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken = store.getState().user.token;
        let signature;
        const signatureStored = localStorage.getItem("user_signature");
        if (signatureStored) {
          signature = signatureStored;
        } else {
          signature = uuid();
          localStorage.setItem("user_signature", signature);
        }
        const opts = {
          reconnect: true
        };
        opts.query = {
          toUserId: withUserId,
          origin: "web",
          authorization: authToken || "",
          signature,
          type
        };
        socket = io.connect(config.socketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        socket.on("connect", () => {
          clearInterval(interval);
          console.log("Socket.io connected.");
          resolve(socket);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
      } else resolve(socket);
    }),
  connectOnboardingChatFlow: (
    authToken,
    skipRequirementsCheck,
    skipAiMessage
  ) =>
    new Promise((resolve, reject) => {
      if (!socket) {
        // const authToken = store.getState().user.token;

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          type: "ONBOARDING",
          source: localStorage.getItem("inviteUserId")||"",
          authorization: authToken || localStorage.getItem("token") || "",
          skipAiMessage: skipAiMessage || undefined,
          skipRequirementsCheck
        };
        removeUndefinedKeys(opts.query);
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          resolve(socket);
        });
      } else resolve(socket);
    }),
  connectInterview: (offerId) =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          offerId,
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
    connectRealtimeInterview: (offerId) =>
      new Promise((resolve, reject) => {
        if (!socket) {
          const authToken =
            store.getState().user.token || localStorage.getItem("token");
  
          const opts = {
            reconnect: true
          };
          opts.query = {
            origin: "web",
            type: "REALTIME-INTERVIEW",
            offerId,
            skipAudioSynthesis: true,
            authorization: authToken || ""
          };
          socket = io.connect(config.interviewSocketBaseUrl, opts);
          socket.on("connect_error", (err) => {
            console.log(`connect_error due to ${err.message}`);
            reject(err);
          });
          interval = setInterval(() => {
            if (socket.connected) {
              clearInterval(interval);
              resolve(socket);
            }
          }, 200);
          socket.on("connect", () => {
            console.log("Socket.io connected.");
            clearInterval(interval);
            resolve(socket);
          });
        } else resolve(socket);
      }),
    connectCounselor: (sessionId) =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          type: "COUNSELING",
          sessionId,
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
    rohanbot: () =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          type: "ROHAN",
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
    sakshibot: () =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          type: "SAKSHI",
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
    connectTrainer: (sessionId) =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          sessionId,
          type: "TRAINING",
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
    connectMockInterviewer: (sessionId) =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          sessionId,
          type: "MOCKINTERVIEW",
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
    connectPanditJi: () =>  //sessionId
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          //sessionId,
          type: "PANDITJI",
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
    connectLifeCoach: (sessionId) =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          sessionId,
          type: "LIFECOACH",
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
    connectFitnessCoach: (sessionId) =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          sessionId,
          type: "FITNESS",
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
    connectSkillGapAnalysis: (sessionId) =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");

        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          sessionId,
          type: "SKILLGAPANALYSIS",
          skipAudioSynthesis: true,
          authorization: authToken || ""
        };
        socket = io.connect(config.interviewSocketBaseUrl, opts);
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            clearInterval(interval);
            resolve(socket);
          }
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
  connectPitchSocket: (accessCode) =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");
        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          registrationCode: accessCode,
          authorization: authToken || ""
        };
        socket = io.connect(config.gsPitchBaseUrl, opts);
        console.log("connection attempt made");
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            console.log("resolved due to interval");
            clearInterval(interval);
            resolve(socket);
            return;
          }
          console.log("still not resolved");
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
  connectHirerFAQChatSocket: () =>
    new Promise((resolve, reject) => {
      if (!socket) {
        const authToken =
          store.getState().user.token || localStorage.getItem("token");
        const opts = {
          reconnect: true
        };
        opts.query = {
          origin: "web",
          authorization: authToken || "",
          type: "HIRER_FAQ"
        };
        socket = io.connect(config.gsPitchBaseUrl, opts);
        console.log("connection attempt made");
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
          reject(err);
        });
        interval = setInterval(() => {
          if (socket.connected) {
            console.log("resolved due to interval");
            clearInterval(interval);
            resolve(socket);
            return;
          }
          console.log("still not resolved");
        }, 200);
        socket.on("connect", () => {
          console.log("Socket.io connected.");
          clearInterval(interval);
          resolve(socket);
        });
      } else resolve(socket);
    }),
  getInstanceAsync: () => socket,
  getInstance: () =>
    new Promise((resolve) => {
      if (socket && socket.connected) {
        resolve(socket);
        return;
      }
      const checkCon = setInterval(() => {
        if (socket && socket.connected) {
          clearInterval(checkCon);
          resolve(socket);
        }
      }, 1000);
    }),
  destroy: () => {
    if (socket) socket.disconnect();
    socket = null;
  }
};
export default websocketManager;
