import React from "react";

export default function DropIcon() {
  return (
    <svg viewBox="0 0 24 24">
      <g transform="translate(0 24) rotate(-90)">
        <rect id="Mask-2" data-name="Mask" width="24" height="24" fill="none" />
        <g
          id="_20x20_arrow-back--grey-2"
          data-name="20x20/arrow-back--grey"
          clipPath="url(#clip-path)"
        >
          <g id="Group_2" data-name="Group 2" transform="translate(8 7)">
            <path
              id="Rectangle"
              d="M0,6.586V0H6.586"
              transform="translate(0.686 5.157) rotate(-45)"
              fill="none"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
