import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "@/redux/store";

import { setToastMessage } from "@/redux/actions/globalStateActions";

export function showToastMessage(typeProps, messageProps) {
  let type = typeProps;
  let message = messageProps;
  if (message === "info" || message === "error") {
    const temp = type;
    type = message;
    message = temp;
  }
  store.dispatch(
    setToastMessage({
      type,
      message
    })
  );
}
export default function ToastComponent() {
  const timeout = useRef(null);
  const dispatch = useDispatch();
  const toastMessage = useSelector((state) => state.globalState.toastMessage);
  const hideToast = () =>
    dispatch(
      setToastMessage({
        type: "",
        message: ""
      })
    );
  useEffect(() => {
    console.log("running");
    if (toastMessage.message) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(hideToast, 4000);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [toastMessage.message]);
  if (toastMessage.message)
    return (
      <div className="onboardingLayout__toast-container">
        <div
          className={`onboardingLayout__toast-indicator onboardingLayout__toast-indicator-${toastMessage.type}`}
        />
        <div className="onboardingLayout__toast-msg">
          {toastMessage.message}
        </div>
        <div className="onboardingLayout__toast-cross" onClick={hideToast}>
          <svg viewBox="0 0 33.96 34.04">
            <path
              fill="#475061"
              d="M168.13,325.49l-14.58,14.57L139,325.49c-2.3-.15-2.92,2-1.86,3l14,14-14.58,14.58c-.15,2.3,2,2.91,3,1.85l14-14,14.58,14.58c2.3.14,2.92-2,1.85-3l-14-14,14-14C171.05,327.45,170.43,325.34,168.13,325.49Z"
              transform="translate(-136.53 -325.48)"
            />
          </svg>
        </div>
      </div>
    );
  return null;
}
