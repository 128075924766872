import React from "react";
import AnimatedLogo from "@/components/AnimatedLogo";
import styles from "./Loader.module.css";

export default function Loader({ color }) {
  return (
    <div className={styles.container}>
      <AnimatedLogo color={color} />
    </div>
  );
}