import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux'; 
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ items }) => {
  const user = useSelector((state) => state.user);
  return (
    <>
      {user?.token &&
        <div className={styles.breadcrumb}>
          {items && items.map((item, index) => {
            const isLast = index === items.length - 1;
            return (
              <React.Fragment key={index}>
                {item.url ? (
                  <NavLink to={item.url} className={styles.link}>
                    {item?.text}
                  </NavLink>
                ) : (
                  <div>{item?.text}</div>
                )}
                {!isLast && ' > '}
              </React.Fragment>
            );
          })}
        </div>
      }
    </>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default Breadcrumbs;
