import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { detect } from "detect-browser";
import config from "@/config/appConfig";
import store from "@/redux/store";

const browser = detect();

const notLoggedInTokenStaging =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTQ3NTA4MjYsImlhdCI6MTYyNzgyNDYyMn0.JRxQp2HkoXJICN_Kzqm8bER8olyiGPH19cwEFuBqFe0"; // dev
const notLoggedInTokenProd =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImlhdCI6MTYyMjYwNDY4MH0.ZQxGVUbEZFpSyRYNEeTQysh4fNomDM_RS5XUSqx7YyY"; // prod

const notLoggedInToken =
  config.nodeEnv === "PRODUCTION"
    ? notLoggedInTokenProd
    : notLoggedInTokenStaging;

const mainInstance = axios.create({
  baseURL: config.baseUrl,
  headers: {
    "device-id": uuidv4(),
    "device-type": "web"
  }
});
if (browser) {
  mainInstance.defaults.headers.userdevice = `${browser.name} ${browser.version} - ${browser.os}`;
}

mainInstance.interceptors.request.use((instanceConfig) => {
  const { token } = store.getState().user;
  if (!instanceConfig.headers.Authorization) {
    // eslint-disable-next-line no-param-reassign
    instanceConfig.headers.Authorization =
      token || localStorage.getItem("token") || notLoggedInToken;
  }
  return instanceConfig;
});
export default mainInstance;
