import React from "react";
import ReactGA from "react-ga4";
import { useHistory } from "react-router-dom";

const RouteTracking = () => {
  const history = useHistory();
  history.listen((location) => {
    // ReactGA.pageview(location.pathname);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname
    });
  });

  return <div />;
};

export default RouteTracking;
