import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import search from "@/assets/svg/search-icon.svg";
import { convertToYearly as convertToYearlyUtility } from "@/utils/compensationFunctions";
import { getPlacesPrediction } from "@/services/mapboxGeocodeService";
import { fetchSpecificConfigApi } from "@/services/utilitiesService";
import styles from "./CustomSelect.module.css";
import { SearchIcon, DropIcon, LocationIcon } from "./Icons";
import DropIcon2 from "./Icons/DropIcon2";

export default function CustomSelect({
  type,
  searchType,
  onLoadOptions,
  disabled,
  options,
  iconColor,
  selectedValue,
  selectedOption,
  convertToYearly,
  searchable,
  optionsIndirect,
  resetAfterSelecting,
  allowNewOptions,
  variant,
  placeholder,
  onChange,
  hoveringIndexToStart,
}) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [ogData, setOgData] = useState([]);
  const [selectedVal, setSelectedVal] = useState("");
  const [hoveringIndex, setHover] = useState(hoveringIndexToStart || 0);
  const [loading, setLoading] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const mainContainer = useRef(null);
  const focusedOption = useRef(null);
  const beforeFocusedOption = useRef(null);
  const afterFocusedOption = useRef(null);
  const inputField = useRef(null);
  const timeout = useRef(null);
  const getUrl = (typeArg, searchTypeArg = "config", value) => {
    switch (searchTypeArg) {
      case "config":
        return `config/searchConfig?config=${typeArg}&search=${"%"}`;
      case "contact":
        return `/contact/search?query=${"%"}`;
      case "organisation":
        return `/config/organisation?search=${value}`;
      case "school":
        return `/config/organisation?search=${value}&edu=1`;
      case "degree":
        return `config/searchConfig?config=degree&search=${value}`;
      case "field":
        return `config/searchConfig?config=degree_field&search=${value}`;
      default:
        return "";
    }
  };
  const getData = (val = null) => {
    setLoading(true);
    const url = getUrl(type, searchType, val);

    fetchSpecificConfigApi(url).then((res) => {
      setData(res.data.data);
      if (onLoadOptions) {
        onLoadOptions(res.data.data);
      }
      setOgData(res.data.data);
      setLoading(false);
      if (!setLoadedOnce) setLoadedOnce(true);
    });
    // .catch((err) =>
  };

  const openList = () => {
    if (disabled) return;
    setOpen(true);
    if (inputField.current) {
      inputField.current.focus();
    }
  };
  const closeList = () => {
    setOpen(false);
  };
  const checkClickOutside = (e) => {
    if (mainContainer.current && !mainContainer.current.contains(e.target)) {
      closeList();
    }
  };
  useEffect(() => {
    if (
      (variant === 5 || variant === 10) &&
      options &&
      options[0] &&
      options[0].id
    ) {
      setData(options);
      setOgData(options);
    }
    if (
      (options && options.length) ||
      searchType === "organisation" ||
      searchType === "school" ||
      searchType === "degree" ||
      searchType === "field" ||
      (!searchType && !type)
    )
      return;
    if (type !== "mapboxPlacesAutocomplete") getData();
  }, []);

  useEffect(() => {
    if (!selectedValue === true && selectedVal !== "") setSelectedVal("");
    if (selectedValue === "Remote") {
      setSelectedVal("Remote");
    }
    if (selectedValue && selectedVal === "") setSelectedVal(selectedValue);
    if (selectedValue !== selectedVal) {
      setSelectedVal(selectedValue);
    }
    if (selectedOption) {
      if (selectedOption.value === "") setSelectedVal("");
      const option = options.find((option_) => option_.id === selectedOption);
      if (option) {
        if (convertToYearly) {
          setSelectedVal(convertToYearlyUtility(option.value));
        } else setSelectedVal(option.value);
      }
    }
    if (
      searchType === "organisation" ||
      searchType === "school" ||
      searchType === "degree" ||
      searchType === "field"
    ) {
      getData(selectedValue);
    }
    return () => {};
  }, [selectedValue, selectedOption]);

  const filter = (e) => {
    if (searchable === false) return;
    if (!open) {
      openList();
      return;
    }
    setLoading(true);
    if (
      searchType === "organisation" ||
      searchType === "school" ||
      searchType === "degree" ||
      searchType === "field"
    ) {
      setSelectedVal(e.target.value);
      getData(e.target.value);
      setHover(0);
      return;
    }
    setHover(0);
    setSelectedVal(e.target.value);
    const filter_val = e.target.value.toLowerCase();
    const filteredData = ogData.filter((val) =>
      searchType === "contact"
        ? val.contact_name.toLowerCase().substring(0, filter_val.length) ===
          filter_val
        : type !== "country_code"
        ? val.value.toLowerCase().substring(0, filter_val.length) === filter_val
        : val.value.toLowerCase().includes(filter_val)
    );
    setLoading(false);
    setData(filteredData);
  };
  const fetchPredictions = async (val) => {
    const predictions = await getPlacesPrediction(val);
    setData(predictions);
    setLoading(false);
    if (!loadedOnce) setLoadedOnce(true);
  };
  const getPlacePredictions = (e) => {
    clearTimeout(timeout.current);
    if (!open) {
      openList();
      return;
    }
    if (e.target.value === "") {
      setData([]);
      setSelectedVal("");
      setLoading(false);
      return;
    }
    setLoading(true);
    const searchValue = e.target.value;
    setSelectedVal(e.target.value);
    timeout.current = setTimeout(() => {
      fetchPredictions(searchValue);
    }, 300);
  };

  const chooseItem = (index) => {
    if (options && type !== "country_code") {
      onChange(options[index]);
      if (optionsIndirect) {
        if (convertToYearly) {
          setSelectedVal(convertToYearlyUtility(options[index].value));
        } else setSelectedVal(options[index].value);
      } else setSelectedVal(options[index]);
    } else if (searchType === "organisation" || searchType === "school") {
      onChange([data[index]]);
      setSelectedVal(data[index].organisation);
    } else if (searchType === "contact") {
      onChange([data[index]]);
      setData([...ogData]);
      setSelectedVal("");
    } else if (type === "country_code") {
      onChange(data[index]);
      setSelectedVal(!resetAfterSelecting ? data[index].value : "");
    } else if (type !== "mapboxPlacesAutocomplete") {
      onChange([data[index]]);
      setSelectedVal(!resetAfterSelecting ? data[index].value : "");
    } else {
      onChange({
        label: data[index].place_name,
        value: data[index],
      });
      setSelectedVal(data[index].place_name);
    }
    if (resetAfterSelecting) {
      setData([...ogData]);
    }
    setOpen(false);
  };
  const handleKeyDown = (e) => {
    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode === 13) {
      chooseItem(hoveringIndex);
    } else if (keyCode === 38) {
      if (hoveringIndex !== 0) {
        setHover(hoveringIndex - 1);
      }
      if (afterFocusedOption.current) {
        afterFocusedOption.current.scrollIntoView({
          block: "nearest",
        });
      }
    } else if (keyCode === 40) {
      const length = options ? options.length : data.length;
      if (hoveringIndex !== length - 1) {
        setHover(hoveringIndex + 1);
      }
      if (beforeFocusedOption.current) {
        beforeFocusedOption.current.scrollIntoView({
          block: "nearest",
        });
      }
    }
  };
  const addOption = () => {
    if (searchType === "organisation") {
      onChange([{ organisation: selectedVal }]);
    } else onChange([{ value: selectedVal }]);
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", checkClickOutside);
    document.addEventListener("contextmenu", checkClickOutside);
    return () => {
      document.removeEventListener("click", checkClickOutside);
      document.removeEventListener("contextmenu", checkClickOutside);
    };
  }, []);
  const captureHovering = (index) => {
    setHover(index);
  };
  useEffect(() => {
    if (focusedOption.current)
      focusedOption.current.scrollIntoView({
        block: "nearest",
      });
  }, [!!focusedOption.current, options?.length, options && options[0]?.value]);

  return (
    <div className={styles.mainContainer} ref={mainContainer}>
      {variant !== undefined && variant !== 1 && variant < 4 ? (
        <Form.Group
          className={variant === 2 ? styles.search_bar : ""}
          onClick={openList}
        >
          <InputGroup
            className={
              variant === 2
                ? styles.search_input_container
                : styles.search_input_container2
            }
          >
            <Form.Control
              placeholder={placeholder || "Choose location"}
              value={selectedVal}
              type="text"
              ref={inputField}
              onKeyDown={handleKeyDown}
              onChange={
                type !== "mapboxPlacesAutocomplete"
                  ? filter
                  : getPlacePredictions
              }
              autoComplete={false}
              onFocus={openList}
              className={styles.search_input}
            />
            <InputGroup.Prepend>
              <InputGroup.Text className={styles.ic}>
                {variant === 2 ? (
                  <img src={search} alt="" />
                ) : (
                  <div className={styles.searchBtnContainer}>
                    <SearchIcon />
                  </div>
                )}
              </InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </Form.Group>
      ) : variant === 5 || variant === 10 || variant === 6 ? (
        <div
          className={`${styles.searchContainer} ${
            variant === 5
              ? styles.searchContainerDesignOverhaul
              : variant === 10
              ? styles.searchContainerDesignOverhaul3
              : styles.searchContainerDesignOverhaul2
          }`}
          onClick={openList}
        >
          <div className={styles.searchInputContainer}>
            <input
              className={`${styles.searchInput} ${styles.searchInputDesignOverhaul}`}
              placeholder={placeholder || "Choose location"}
              value={selectedVal}
              readOnly={searchable === false}
              ref={inputField}
              type="text"
              onKeyDown={handleKeyDown}
              onChange={
                type !== "mapboxPlacesAutocomplete"
                  ? filter
                  : getPlacePredictions
              }
              onFocus={openList}
            />
          </div>
          {loading ? (
            <div className={styles.spinner} />
          ) : variant === 5 || variant === 10 ? (
            ""
          ) : (
            <div className={styles.spinnerEmpty} />
          )}
          {type === "country_code" ? (
            <div className={styles.searchBtnContainer2}>
              <DropIcon2 />
            </div>
          ) : (
            <div className={styles.searchBtnContainer}>
              {options ? (
                <DropIcon />
              ) : type !== "mapboxPlacesAutocomplete" ? (
                <SearchIcon iconColor={iconColor} />
              ) : (
                <LocationIcon />
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          className={`${styles.searchContainer} ${
            variant === 4 ? styles.minimalSearchContainer : ""
          }`}
          onClick={openList}
        >
          <div className={styles.searchInputContainer}>
            <input
              className={styles.searchInput}
              placeholder={placeholder || "Choose location"}
              value={selectedVal}
              readOnly={searchable === false}
              ref={inputField}
              type="text"
              onKeyDown={handleKeyDown}
              onChange={
                type !== "mapboxPlacesAutocomplete"
                  ? filter
                  : getPlacePredictions
              }
              onFocus={openList}
            />
          </div>
          {loading ? (
            <div className={styles.spinner} />
          ) : (
            <div className={styles.spinnerEmpty} />
          )}
          <div className={styles.searchBtnContainer}>
            {options ? (
              <DropIcon />
            ) : type !== "mapboxPlacesAutocomplete" ? (
              <SearchIcon iconColor={iconColor} />
            ) : (
              <LocationIcon />
            )}
          </div>
        </div>
      )}
      <div
        className={`${styles.resListContainer} ${
          open ? styles.resListContainerOpen : ""
        } ${variant === 2 ? styles.resListContainerAlt : ""}
        ${variant === 5 || variant === 10 ? styles.resListContainerAlt5 : ""}
        ${variant === 6 ? styles.resListContainerAlt6 : ""}
        ${type === "country_code" ? styles.resListContainerCountryCode : ""}
        `}
        // onKeyDown={onKeyPressed}
        // tabIndex="0"
      >
        <div className={styles.resList}>
          {type === "mapboxPlacesAutocomplete" && loading ? (
            <div className={styles.resListItem}>Searching</div>
          ) : (
            !options &&
            data.length === 0 && (
              <>
                {" "}
                <div className={styles.resListItem}>
                  {loading
                    ? selectedVal
                      ? "Searching"
                      : type !== "mapboxPlacesAutocomplete"
                      ? "No results found"
                      : "Type to start searching"
                    : !selectedVal
                    ? "Type to start searching"
                    : "No Results Found"}
                </div>
                {allowNewOptions && selectedVal !== "" ? (
                  <div className={styles.resListItem} onClick={addOption}>
                    Add Option : {selectedVal}
                  </div>
                ) : (
                  ""
                )}
              </>
            )
          )}
          {(variant !== 5 ||
            ((variant === 5 || variant === 10) && options && !options[0].id)) &&
            options &&
            options.map((item, index) => (
              <div
                className={`${styles.resListItem} ${
                  hoveringIndex === index ? styles.resListItemHover : ""
                }`}
                ref={
                  hoveringIndex === index
                    ? focusedOption
                    : hoveringIndex === index - 1
                    ? beforeFocusedOption
                    : hoveringIndex === index + 1
                    ? afterFocusedOption
                    : null
                }
                key={item.id || item}
                onMouseOver={() => {
                  captureHovering(index);
                }}
                onClick={() => {
                  chooseItem(index);
                }}
              >
                {optionsIndirect
                  ? convertToYearly
                    ? convertToYearlyUtility(item.value)
                    : item.value
                  : item}
              </div>
            ))}
          {(variant === 5 || variant === 10 || !options) &&
            !loading &&
            data.map((item, index) => (
              <div
                className={`${styles.resListItem} ${
                  hoveringIndex === index ? styles.resListItemHover : ""
                }`}
                ref={
                  hoveringIndex === index
                    ? focusedOption
                    : hoveringIndex === index - 1
                    ? beforeFocusedOption
                    : hoveringIndex === index + 1
                    ? afterFocusedOption
                    : null
                }
                key={item.id}
                onMouseOver={() => {
                  captureHovering(index);
                }}
                onClick={() => {
                  chooseItem(index);
                }}
              >
                {searchType === "contact"
                  ? item.contact_name
                  : searchType === "organisation" || searchType === "school"
                  ? item.organisation
                  : type !== "mapboxPlacesAutocomplete"
                  ? item.value
                  : item.place_name}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
CustomSelect.propTypes = {
  type: PropTypes.string,
  searchType: PropTypes.string,
  onLoadOptions: PropTypes.func,
  disabled: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedOption: PropTypes.object,
  convertToYearly: PropTypes.bool,
  searchable: PropTypes.bool,
  optionsIndirect: PropTypes.bool,
  resetAfterSelecting: PropTypes.bool,
  allowNewOptions: PropTypes.bool,
  variant: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
};
CustomSelect.defaultProps = {
  type: undefined,
  searchType: undefined,
  onLoadOptions: null,
  disabled: false,
  options: null,
  selectedValue: null,
  selectedOption: null,
  convertToYearly: false,
  searchable: true,
  optionsIndirect: false,
  resetAfterSelecting: false,
  allowNewOptions: false,
  variant: 1,
  placeholder: "",
  iconColor: "",
};
