import store from "@/redux/store";
import appConfig from "@/config/appConfig";

export default function createSSEConnection({ route, queryParams = {}, onProgress, onComplete, onError }) {

  const url = new URL(`${appConfig.baseUrl}/sse/${route}`);


  Object.keys(queryParams).forEach(key => {
    url.searchParams.append(key, queryParams[key]);
  });


  const eventSource = new EventSource(url);

  eventSource.onmessage = (event) => {
    const data = JSON.parse(event.data);
    
    if (data.processStatus === "COMPLETED") {
      console.log(`SSE: ${route} completed`, data);
      if (onComplete) onComplete(data);
      eventSource.close(); 
    } else if (data.processStatus === "IN_PROGRESS") {
      console.log(`SSE: ${route} in progress`, data);
      if (onProgress) onProgress(data); 
    }
  };

  eventSource.onerror = (error) => {
    console.error(`SSE: Error in connection to ${route}`, error);
    if (onError) onError(error); 
    eventSource.close(); 
  };

  return eventSource; 
}