import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import gcoinIcon from "@/assets/svg/gcoin.svg";
import { getCompensationFromIdNew } from "@/utils/compensationFunctions";
import styles from "./ListCard.module.scss";

export default function ListCard({
  data,
  type,
  acceptOffer,
  rejectOffer,
  messageHirer,
  deleteCard,
  onMessageClick,
  noButtons = false,
  additionalClassName,
  noTimeShown
}) {
  return (
    <div className={`${styles.container} ${additionalClassName || ""}`}>
      {data.archived ? (
        <div className={styles.overlay}>Card has been archived</div>
      ) : (
        ""
      )}
      {!noTimeShown &&
        data.request_status !== "ACCEPTED" &&
        !data.opportunityGrabbed &&
        (!data.applyStatus || data.applyStatus === "ACCEPTED") &&
        type === "APPLIED" &&
        !data.messageUnlocked &&
        !data.jobClosed &&
        (data.timeLeft || data.timeLeft === 0) && (
          <div
            className={styles.timeLeftRow}
            style={
              type === "APPLIED"
                ? { flexDirection: "row-reverse", justifyContent: "flex-end" }
                : null
            }
          >
            <div className={styles.timeLeft}>
              <div className={styles.timeLeftCircle}>
                <CircularProgressbarWithChildren
                  value={
                    data.timeLeft === 0
                      ? 100
                      : 100 - ((72 - data.timeLeft) / 72) * 100
                  }
                  // circleRatio={0.75}
                  counterClockwise
                  strokeWidth={10}
                  styles={buildStyles({
                    rotation: 0,
                    pathColor: data.timeLeft === 0 ? "#F23342" : "#389fff"
                  })}
                >
                  {data.timeLeft} hr
                </CircularProgressbarWithChildren>
              </div>
            </div>
            <div
              className={styles.timeLeftText}
              style={
                type === "APPLIED"
                  ? { marginLeft: "0", marginRight: ".5rem" }
                  : null
              }
            >
              {type === "APPLIED" ? (
                "You can message this hirer after"
              ) : (
                <>
                  {data.expired ? "You can still" : "Time left to"} grab this
                  opportunity
                </>
              )}
            </div>
          </div>
        )}
      {type === "APPLIED" && data.jobClosed && (
        <div className={styles.jobClosedText}>Closed!</div>
      )}
      <div
        className={`${styles.content} ${
          data.jobClosed ? styles.contentGreyed : ""
        }`}
      >
        {data.jobTitle && (
          <div className={styles.titleRow}>
            <div className={styles.title}>{data.jobTitle}</div>
            {!noButtons && (
              <div className={styles.deleteIcon} onClick={deleteCard}>
                <DeleteIcon />
              </div>
            )}
          </div>
        )}
        {(data.jobType || data.location_city) && (
          <div className={styles.textDetails}>
            {data.jobType} {data.jobType && data.location_city ? "●" : ""}{" "}
            {data.location_city}
          </div>
        )}
        {!!(
          data.upperworkex ||
          data.monthlyCompensationId ||
          data.hourlyCompensationId
        ) && (
          <div className={styles.cardDetails}>
            {!!data.upperworkex && (
              <div className={styles.cardDetail}>
                <ExpIcon /> {data.lowerworkex}-{data.upperworkex} Years
              </div>
            )}
            {(data.monthlyCompensationId || data.hourlyCompensationId) && (
              <div className={styles.cardDetail}>
                <CompIcon />{" "}
                {getCompensationFromIdNew(
                  data.monthlyCompensationId || data.hourlyCompensationId
                )}
              </div>
            )}
          </div>
        )}
        <div className={styles.recruiterDetails}>
          <div className={styles.titleRow}>
            <div className={styles.recruiterDetailsLabel}>Recruiter :</div>
            {!data.jobTitle && !noButtons && (
              <div className={styles.deleteIcon} onClick={deleteCard}>
                <DeleteIcon />
              </div>
            )}
          </div>

          <div className={styles.recruiterDetailsRow}>
            <img
              src={data.hirerPfp}
              alt=""
              className={styles.recruiterDetailsImg}
            />
            <div className={styles.recruiterDetailsScore}>
              {Math.round(data.hirerScore * 10) / 10}
            </div>
            <div className={styles.recruiterDetailsText}>
              <div className={styles.recruiterDetailsTextName}>
                {data.hirerName}
              </div>
              <div className={styles.recruiterDetailsTextCompany}>
                {data.hirerCompany}
              </div>
            </div>
          </div>
        </div>
        {!noButtons && (
          <div className={styles.buttons}>
            {data.forcedStatus ? (
              <>
                {data.forcedStatus === "ACCEPTED" && (
                  <button
                    className={styles.msgBtn}
                    onClick={() => onMessageClick(data)}
                  >
                    Message
                  </button>
                )}
                {data.forcedStatus === "REJECTED" && (
                  <button className={styles.msgBtn} onClick={null}>
                    Offer Rejected
                  </button>
                )}
              </>
            ) : (
              <>
                {type === "PENDING" && (
                  <>
                    {data.type === "OFFER" && (
                      <>
                        <button
                          className={styles.rejectBtn}
                          onClick={() => rejectOffer(data.hirerUserId)}
                        >
                          Reject
                        </button>
                        <button
                          className={styles.grabOpportunityBtn}
                          onClick={() => acceptOffer(data.hirerUserId)}
                        >
                          <div className={styles.grabOpportunityBtnText}>
                            Accept
                          </div>
                        </button>
                      </>
                    )}
                  </>
                )}
                {(type === "SHORTLISTED" || data.messageUnlocked) && (
                  <button
                    className={styles.msgBtn}
                    onClick={() => onMessageClick(data)}
                  >
                    Message
                  </button>
                )}
                {type === "APPLIED" &&
                  !data.jobClosed &&
                  !data.messageUnlocked && (
                    <button
                      className={`${styles.grabOpportunityBtn}  ${
                        !data.opportunityAvailable
                          ? styles.grabOpportunityBtnDisabled
                          : ""
                      } ${styles.grabOpportunityBtnLg}`}
                      onClick={() =>
                        !data.opportunityAvailable
                          ? null
                          : messageHirer(data.id)
                      }
                    >
                      <div className={styles.grabOpportunityBtnText}>
                        Message
                      </div>
                    </button>
                  )}
              </>
            )}
          </div>
        )}
        {data.message && (
          <div className={styles.cardMsg}>
            <MsgIcon /> {data.message}
          </div>
        )}
      </div>
    </div>
  );
}

const ExpIcon = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8462 2.33333H10.961V1.75C10.9604 1.28603 10.7779 0.841214 10.4535 0.513139C10.129 0.185063 9.68906 0.000521086 9.23019 0H5.76865C5.30978 0.000521086 4.86985 0.185063 4.54538 0.513139C4.22091 0.841214 4.0384 1.28603 4.03788 1.75V2.33333H1.15385C0.847933 2.33368 0.554647 2.45671 0.338333 2.67543C0.122019 2.89414 0.000343573 3.19069 0 3.5V12.8333C0.000343573 13.1426 0.122019 13.4392 0.338333 13.6579C0.554647 13.8766 0.847933 13.9997 1.15385 14H13.8462C14.1521 13.9997 14.4454 13.8766 14.6617 13.6579C14.878 13.4392 14.9997 13.1426 15 12.8333V3.5C14.9997 3.19069 14.878 2.89414 14.6617 2.67543C14.4454 2.45671 14.1521 2.33368 13.8462 2.33333ZM5.19173 1.75C5.19192 1.59535 5.25277 1.44709 5.36092 1.33773C5.46907 1.22838 5.6157 1.16686 5.76865 1.16667H9.23019C9.38314 1.16686 9.52978 1.22838 9.63793 1.33773C9.74608 1.44709 9.80692 1.59535 9.80712 1.75V2.33333H5.19173V1.75ZM9.80712 3.5V12.8333H5.19173V3.5H9.80712ZM1.15385 3.5H4.03788V12.8333H1.15385V3.5ZM13.8462 12.8333H10.961V3.5H13.8462L13.8469 12.8333H13.8462Z"
      fill="black"
    />
    <path
      d="M9.81111 3.49316H10.9903V12.823H9.77344L9.81111 3.49316Z"
      fill="#F2F9FF"
    />
    <path
      d="M4.01074 3.49316H5.37044V12.823H3.9375L4.01074 3.49316Z"
      fill="#F2F9FF"
    />
  </svg>
);

const CompIcon = () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.6"
      y="0.6"
      width="15.8"
      height="12.8"
      rx="1.1"
      stroke="black"
      strokeWidth="1.2"
    />
    <path
      d="M10.9658 5.62891L10.7412 6.40039H6.20996L6.43457 5.62891H10.9658ZM8.93457 11L6.35156 7.98242L6.34668 7.37695H7.53809C7.88314 7.37695 8.16797 7.31999 8.39258 7.20605C8.62044 7.08887 8.79134 6.93099 8.90527 6.73242C9.01921 6.53385 9.07617 6.31087 9.07617 6.06348C9.07617 5.78678 9.02246 5.54427 8.91504 5.33594C8.80762 5.12435 8.63835 4.95996 8.40723 4.84277C8.17936 4.72233 7.87988 4.66211 7.50879 4.66211H6.21973L6.44922 3.89062H7.50879C8.0752 3.89062 8.54232 3.97689 8.91016 4.14941C9.28125 4.31868 9.55794 4.56608 9.74023 4.8916C9.92253 5.21712 10.0137 5.611 10.0137 6.07324C10.0137 6.47038 9.93717 6.82682 9.78418 7.14258C9.63444 7.45508 9.38542 7.70085 9.03711 7.87988C8.69206 8.05892 8.22656 8.14844 7.64062 8.14844L10.043 10.9414V11H8.93457ZM10.9658 3.89062L10.7412 4.66211H7.08887L7.31348 3.89062H10.9658Z"
      fill="black"
    />
  </svg>
);

const MsgIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 9C9.73479 9 9.48043 9.10536 9.2929 9.29289C9.10536 9.48043 9 9.73478 9 10V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 10 9ZM10.38 5.08C10.1365 4.97998 9.86347 4.97998 9.62 5.08C9.49725 5.12759 9.38511 5.19896 9.29 5.29C9.20167 5.3872 9.13065 5.49882 9.08 5.62C9.02402 5.73868 8.99662 5.86882 9 6C8.99924 6.13161 9.02447 6.26207 9.07423 6.38391C9.124 6.50574 9.19732 6.61656 9.29 6.71C9.38721 6.79833 9.49882 6.86936 9.62 6.92C9.7715 6.98224 9.93597 7.00632 10.099 6.99011C10.2619 6.97391 10.4184 6.91792 10.5547 6.82707C10.691 6.73622 10.8029 6.61328 10.8805 6.46907C10.9582 6.32486 10.9992 6.16378 11 6C10.9963 5.73523 10.8927 5.48163 10.71 5.29C10.6149 5.19896 10.5028 5.12759 10.38 5.08ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
      fill="#717171"
    />
  </svg>
);

const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.25 3C9.25 2.58579 9.58579 2.25 10 2.25H14C14.4142 2.25 14.75 2.58579 14.75 3V3.75H19C19.4142 3.75 19.75 4.08579 19.75 4.5C19.75 4.91421 19.4142 5.25 19 5.25H5C4.58579 5.25 4.25 4.91421 4.25 4.5C4.25 4.08579 4.58579 3.75 5 3.75H9.25V3Z"
      fill="#F23342"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.2399 7.94478C6.26803 7.69157 6.48206 7.5 6.73684 7.5H17.2632C17.5179 7.5 17.732 7.69157 17.7601 7.94478L17.9602 9.74613C18.321 12.9931 18.321 16.2701 17.9602 19.517L17.9405 19.6944C17.8091 20.8769 16.8926 21.8199 15.7143 21.9849C13.2501 22.3299 10.7499 22.3299 8.28574 21.9849C7.10737 21.8199 6.19085 20.8769 6.05945 19.6944L6.03975 19.517C5.67897 16.2701 5.67897 12.9931 6.03975 9.74613L6.2399 7.94478ZM14.5303 12.4697C14.8232 12.7626 14.8232 13.2374 14.5303 13.5303L13.0607 15L14.5303 16.4697C14.8232 16.7626 14.8232 17.2374 14.5303 17.5303C14.2374 17.8232 13.7626 17.8232 13.4697 17.5303L12 16.0607L10.5303 17.5303C10.2374 17.8232 9.76257 17.8232 9.46968 17.5303C9.17678 17.2374 9.17678 16.7626 9.46968 16.4697L10.9393 15L9.46967 13.5303C9.17678 13.2374 9.17678 12.7626 9.46967 12.4697C9.76256 12.1768 10.2374 12.1768 10.5303 12.4697L12 13.9393L13.4697 12.4697C13.7626 12.1768 14.2374 12.1768 14.5303 12.4697Z"
      fill="#F23342"
    />
  </svg>
);
