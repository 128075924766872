/* eslint-disable no-case-declarations */
import {
  LOGIN,
  LOGOUT,
  SET_USER_AS_ACTIVE,
  UPDATE_PFP,
  UPDATE_USER_DETAILS,
} from "../CONSTANTS";

const token = window.localStorage ? window.localStorage.getItem("token") : "";
const initialState = {
  loading: !!token,
  user_id: null,
  country_code: "",
  image_id: "",
  name: "",
  dob:"",
  email_id: "",
  status: 0,
  invite_link: "",
  token: token || "",
  mobile_number: "",
  userMode: "JOB_SEEKER",
  needToDoOnboarding: true,
  currency: "INR",
  previousUserMode: {
    mode: "",
    created_at: ""
  },
  hiring: {
    data: {
      userSavedMode: "HIRING",
      location_city: "",
      isProfileVerified: false,
      referralCode: "",
      gcoins: 0,
      profileStrength: 0,
      company_gst: null,
      organisation_id: null,
      organisation: "",
      designation: "",
      designation_id: null,
      industry_type: "",
      industry_type_id: null,
      annual_turnover: "",
      annual_turnover_id: null,
      website: "",
      address: "",
      company_size: "",
      linkedin_url: "",
      description: null

    },
    loading: false,
    error: null
  },
  social: {
    data: {
      age: null,
      dob: null,
      gender: null,
      profileUrl: null,
      feedbacksReceived: 0,
      selfFeedback: null,
      isProfileVerified: null,
      location: null,
      score: null,
      bio: '',
      height: 0,
      weight: 0,
      wouldLikeToDonateBlood: false,
      bloodGroup: null,
      relationshipStatus: null,
      annualIncome: null,
      fitness: null,
      interestedIn: null,
      images: [],
      interests: [],
      lookingFor: [],
      topTraits: [],
      education: [],
      workExperience: [],
      profilePercentageData: {},
      designation: ''
    },
    loading: false,
    error: null
  },
  job_seeker: {
    data:{
      referralCode: "",
      projects: [],
      resume: null,
      languages: [],
      certificates: [],
      awards: [],
      gender: null,
      workExperienceRange: {},
      skills: [],
      dob: null,
      experience: [],
      education: [],
      bio: "",
      parameters:[],
      profile_percentage:0,
      score:0,
      isProfileVerified:false
    },
    loading: false,
    error: null
  }
};

const userReducer = (state = initialState, action) => {
  // eslint-disable-next-line prefer-const
  let { mode, payload } = action;
  if (mode === "SOCIAL") {
    mode = "JOB_SEEKER";
  }

  switch (action.type) {
    // case LOGIN:
    //   return { ...state, loading: false, ...action.payload };
    case LOGIN:
      if (mode) {
        return {
          ...state,
          loading: false,
          [mode.toLowerCase()]: {
            ...state[mode.toLowerCase()],
            data: {
              ...state[mode.toLowerCase()].data,
              ...payload,
            },
          },
        };
      }
      const generalUpdatesDetails = { ...payload };
      const deleteSpecificKeys = ['hiring', 'social', 'jobSeeker'];
      deleteSpecificKeys.forEach(key => delete generalUpdatesDetails[key]);

      return {
        ...state,
        loading: false,
        ...generalUpdatesDetails,
      };
    case SET_USER_AS_ACTIVE:
      return {
        ...state,
        userMode: action.payload,
        hirerModeUnlocked: action.payload === "HIRER",
        status: 3,
      };
    case UPDATE_PFP:
      return {
        ...state,
        image_id: action.payload,
      };

    
    case UPDATE_USER_DETAILS:
      if (mode) {
        return {
          ...state,
          ...payload,
          loading: false,
          [mode.toLowerCase()]: {
            ...state[mode.toLowerCase()],
            data: {
              ...state[mode.toLowerCase()].data,
              ...payload,
            },
          },
        };
      }
      if (payload.userMode && !payload.previousUserMode) {
        payload.previousUserMode = {
          mode: state.userMode,
          created_at: new Date(),
        };
      }
      const generalUpdates = { ...payload };
      const modeSpecificKeys = ['hiring', 'social', 'jobSeeker'];
      modeSpecificKeys.forEach(key => delete generalUpdates[key]);

      return {
        ...state,
        loading: false,
        ...generalUpdates,
      };

    case LOGOUT:
      return {
        loading: false,
        user_id: null,
        country_code: "",
        image_id: "",
        name: "",
        dob:"",
        email_id: "",
        status: 0,
        invite_link: "",
        token: "",
        mobile_number: "",
        userMode: "JOB_SEEKER",
        needToDoOnboarding: true,
        currency: "INR",
        previousUserMode: {
          mode: "",
          created_at: ""
        },
        hiring: {
          data: {
            userSavedMode: "HIRING",
            location_city: "",
            isProfileVerified: false,
            referralCode: "",
            gcoins: 0,
            profileStrength: 0,
            company_gst: null,
            organisation_id: null,
            organisation: "",
            designation: "",
            designation_id: null,
            industry_type: "",
            industry_type_id: null,
            annual_turnover: "",
            annual_turnover_id: null,
            website: "",
            address: "",
            company_size: "",
            linkedin_url: "",
            description: null
      
          },
          loading: false,
          error: null
        },
        social: {
          data: {
            age: null,
            dob: null,
            gender: null,
            profileUrl: null,
            feedbacksReceived: 0,
            selfFeedback: null,
            isProfileVerified: null,
            location: null,
            score: null,
            bio: '',
            height: 0,
            weight: 0,
            wouldLikeToDonateBlood: false,
            bloodGroup: null,
            relationshipStatus: null,
            annualIncome: null,
            fitness: null,
            interestedIn: null,
            images: [],
            interests: [],
            lookingFor: [],
            topTraits: [],
            education: [],
            workExperience: [],
            profilePercentageData: {},
            designation: ''
          },
          loading: false,
          error: null
        },
        job_seeker: {
          data:{
            referralCode: "",
            projects: [],
            resume: null,
            languages: [],
            certificates: [],
            awards: [],
            gender: null,
            workExperienceRange: {},
            skills: [],
            dob: null,
            experience: [],
            education: [],
            bio: "",
            parameters:[],
            profile_percentage:0,
            score:0,
            isProfileVerified:false
          },
          loading: false,
          error: null
        }
      }
    default:
      return state;
  }
};
export default userReducer;
