import React from "react";
import PropTypes from "prop-types";

export default function SearchIcon({ iconColor }) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 51.8 51.8"
      style={{
        overflow: "visible",
        enableBackground: "new 0 0 51.8 51.8",
      }}
    >
      <path
        style={{
          stroke: iconColor || "#000000",
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="M50.4,46.5L35.8,31.9c2.4-3.2,3.9-7.2,3.9-11.6C39.7,9.7,31,1,20.3,1S1,9.7,1,20.3s8.7,19.3,19.3,19.3
    c4.3,0,8.3-1.5,11.6-3.9l14.6,14.6L50.4,46.5z M2.8,20.3c0-9.6,7.8-17.5,17.5-17.5s17.5,7.8,17.5,17.5S30,37.8,20.3,37.8
    S2.8,30,2.8,20.3z M33.8,34.2c0.2-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.3-0.3,0.4-0.4l13.1,13.1l-1.3,1.3L33.3,34.6
    C33.5,34.5,33.6,34.4,33.8,34.2z"
      />
    </svg>
  );
}
SearchIcon.propTypes = {
  iconColor: PropTypes.string,
};
SearchIcon.defaultProps = {
  iconColor: "",
};
