import {
  SET_TOAST_MESSAGE,
  SET_ONBOARDING_STATUS,
  SHOW_INSUFFICIENT_GCOINS_MODAL,
  HIDE_INSUFFICIENT_GCOINS_MODAL,
  SHOW_ACTIVATE_PITCH_MODAL,
  HIDE_ACTIVATE_PITCH_MODAL,
  SHOW_ACTIVATE_INTRO_MODAL,
  HIDE_ACTIVATE_INTRO_MODAL
} from "../CONSTANTS";

export const setToastMessage = ({ type, message }) => ({
  type: SET_TOAST_MESSAGE,
  payload: { message, type }
});
export const setOnboardingStatus = (data) => ({
  type: SET_ONBOARDING_STATUS,
  payload: data
});
export const showInsufficientGCoinsModal = (data) => ({
  type: SHOW_INSUFFICIENT_GCOINS_MODAL,
  payload: data
});
export const hideInsufficientGCoinsModal = () => ({
  type: HIDE_INSUFFICIENT_GCOINS_MODAL
});
export const showActivatePitchModal = () => ({
  type: SHOW_ACTIVATE_PITCH_MODAL
});
export const hideActivatePitchModal = () => ({
  type: HIDE_ACTIVATE_PITCH_MODAL
});
export const showActivateIntroModal = () => ({
  type: SHOW_ACTIVATE_INTRO_MODAL
});
export const hideActivateIntroModal = () => ({
  type: HIDE_ACTIVATE_INTRO_MODAL
});
export const setIpCountry = (data) => ({
  type: "SET_IP_COUNTRY",
  payload: data
});
