import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetStateHire, resetStateJob } from "@/redux/actions/filterActions";

export default function PushPopHandler() {
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(
    () =>
      history.listen((location) => {
        if (
          (history.location.pathname.includes("/hire") ||
            history.location.pathname.includes("-hire") ||
            history.location.pathname.includes("/dashboard") ||
            history.location.pathname === "/") &&
          history.action !== "POP" &&
          (!locationKeys[0] ||
            (locationKeys[0] && locationKeys[0].pathname !== location.pathname))
        ) {
          dispatch(resetStateHire(true));
        } else if (
          (history.location.pathname.includes("/jobs") ||
            history.location.pathname.includes("-jobs") ||
            history.location.pathname.includes("/dashboard") ||
            history.location.pathname === "/") &&
          history.action !== "POP" &&
          (!locationKeys[0] ||
            (locationKeys[0] && locationKeys[0].pathname !== location.pathname))
        ) {
          dispatch(resetStateJob(true));
        }
        if (history.action === "PUSH") {
          setLocationKeys([{ key: location.key, pathname: location.pathname }]);
        }

        if (history.action === "POP") {
          if (locationKeys[1] === location.key) {
            // eslint-disable-next-line no-unused-vars
            setLocationKeys(([_, ...keys]) => keys);
            // Handle forward event
          } else {
            setLocationKeys((keys) => [
              { key: location.key, pathname: location.pathname },
              ...keys,
            ]);

            // Handle back event
          }
        }
      }),
    [locationKeys]
  );
  return <div />;
}
