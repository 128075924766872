import React from "react";
import ReactModal from "react-modal";
import ResumeUploader from "./ResumeUploader";
import styles from "./ResumeUploder.module.scss";

export default function ResumeUploaderJobApplyModal({
  isOpen,
  onRequestClose,
  applyToJob,
  onUploadSuccess,
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={(e) => {
        console.log("hjere");
        e.stopPropagation();
        onRequestClose();
      }}
      className={`postJob__completeBanner-modal2 ${
        styles.resumeModal
      }`}
      overlayClassName="postJob__completeBanner-overlay-5"
    >
      <div className={styles.modalContainer}>
        <div className={styles.header}>Must have criteria</div>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <div className={styles.modalHeading}>Upload Your Resume</div>
          <div className={styles.modalSubHeading}>
          Upload your updated resume to proceed further.
          </div>
          <ResumeUploader
            onUploadSuccess={onUploadSuccess}
            showApplyButton
            onActionButtonClick={applyToJob}
            type="MODAL"
          />
        </div>
      </div>
    </ReactModal>
  );
}
