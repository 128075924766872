import {
  SET_FILTER_BY_HIRE,
  UPDATE_FILTER_MAIN_HIRE,
  UPDATE_FILTER_OTHER_HIRE,
  UPDATE_NAME_INPUT_VALUE_HIRE,
  UPDATE_TOF_INPUT_VALUE_HIRE,
  RESET_STATE_HIRE,
  SET_FILTER_BY_JOB,
  UPDATE_FILTER_MAIN_JOB,
  UPDATE_FILTER_OTHER_JOB,
  UPDATE_NAME_INPUT_VALUE_JOB,
  UPDATE_TOF_INPUT_VALUE_JOB,
  RESET_STATE_JOB,
  SET_PAGE_HIRE,
  SET_PAGE_JOB,
  SET_LAST_PAGE_HIRE,
  SET_LAST_PAGE_JOB,
  SET_FILTER_BY_OFFER_ID,
  SET_FILTER_CATEGORY,
} from "../CONSTANTS";

export const setFilterByJob = (data) => ({
  type: SET_FILTER_BY_JOB,
  payload: data,
});
export const updateFilterMainJob = (data) => ({
  type: UPDATE_FILTER_MAIN_JOB,
  payload: data,
});
export const updateFilterOtherJob = ({ filterBy, data }) => ({
  type: UPDATE_FILTER_OTHER_JOB,
  payload: {
    filterBy,
    data,
  },
});

export const updateNameInputValueJob = (value) => ({
  type: UPDATE_NAME_INPUT_VALUE_JOB,
  payload: value,
});

export const updateTofInputValueJob = (value) => ({
  type: UPDATE_TOF_INPUT_VALUE_JOB,
  payload: value,
});
export const resetStateJob = (hardReset) => ({
  type: RESET_STATE_JOB,
  payload: hardReset,
});
export const setPageHire = (val) => ({
  type: SET_PAGE_HIRE,
  payload: val,
});
export const setLastPageHire = (val) => ({
  type: SET_LAST_PAGE_HIRE,
  payload: val,
});

export const setFilterByHire = (data) => ({
  type: SET_FILTER_BY_HIRE,
  payload: data,
});
export const updateFilterMainHire = (data) => ({
  type: UPDATE_FILTER_MAIN_HIRE,
  payload: data,
});
export const updateFilterOtherHire = ({ filterBy, data }) => ({
  type: UPDATE_FILTER_OTHER_HIRE,
  payload: {
    filterBy,
    data,
  },
});

export const updateNameInputValueHire = (value) => ({
  type: UPDATE_NAME_INPUT_VALUE_HIRE,
  payload: value,
});

export const updateTofInputValueHire = (value) => ({
  type: UPDATE_TOF_INPUT_VALUE_HIRE,
  payload: value,
});
export const resetStateHire = (hardReset) => ({
  type: RESET_STATE_HIRE,
  payload: hardReset,
});

export const setPageJob = (val) => ({
  type: SET_PAGE_JOB,
  payload: val,
});
export const setLastPageJob = (val) => ({
  type: SET_LAST_PAGE_JOB,
  payload: val,
});
export const setFilterByOfferId = (val) => ({
  type: SET_FILTER_BY_OFFER_ID,
  payload: val,
});
export const setFilterCategoryJob = (val) => ({
  type: SET_FILTER_CATEGORY,
  payload: val,
});
export const setFilterCategoryHire = (val) => ({
  type: SET_FILTER_CATEGORY,
  payload: val,
});
