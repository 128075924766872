/* eslint-disable import/prefer-default-export */
import { initializeApp } from "firebase/app";
import { initializeAnalytics, logEvent } from "firebase/analytics";

let app = null;
let firebaseAnalytics = null;

const firebaseConfig = {
  apiKey: "AIzaSyB8Nfm7CXC0LsnVqNd9cUypvmt3m_Qr0Jg",
  authDomain: "tustze.firebaseapp.com",
  databaseURL: "https://tustze.firebaseio.com",
  projectId: "tustze",
  storageBucket: "tustze.appspot.com",
  messagingSenderId: "1025256081245",
  appId: "1:1025256081245:web:241b4a59574a379f468ab4",
  measurementId: "G-2G27D9DRP3"
};

const getFirebaseInstance = async () => {
  app = initializeApp(firebaseConfig);
  firebaseAnalytics = initializeAnalytics(app);
  return app;
};
const getFirebaseAnalyticsInstance = async () => {
  console.log(app);
  if (!firebaseAnalytics) console.log(firebaseAnalytics);
  return firebaseAnalytics;
};
const logFirebaseEvent = (event, data) => {
  console.log(event, data);
  logEvent(firebaseAnalytics, event, data);
};
export { getFirebaseInstance, getFirebaseAnalyticsInstance, logFirebaseEvent };
