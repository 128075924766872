import { LOAD_PITCH_DATA,LOGOUT } from "../CONSTANTS";

const initialState = sessionStorage.getItem("createPitchDataForRestoring")
  ? JSON.parse(sessionStorage.getItem("createPitchDataForRestoring"))
  : {
      pitchId: 0,
      pitchDetails: null,
    };
const createPitchReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PITCH_DATA:
      if (action.payload.pitchId) {
        sessionStorage.setItem(
          "createPitchDataForRestoring",
          JSON.stringify(action.payload)
        );
      } else sessionStorage.removeItem("createPitchDataForRestoring");
      return { ...action.payload };
    case LOGOUT:
      sessionStorage.removeItem("createPitchDataForRestoring");
      return {
        pitchId: 0,
        pitchDetails: null,
      };
    default:
      return state;
  }
};
export default createPitchReducer;
