import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { intiatePitchPurchase } from "@/utils/ccAvenue";
// import { displayRazorpayPitch } from "@/utils/razorpay";
import { hideActivatePitchModal } from "@/redux/actions/globalStateActions";
import { mainAxiosInstance as Axios } from "@/services/networkAdapters/axiosInstance";

import styles from "./ActivatePitchModal.module.scss";

export default function ActivatePitchModal({
  modalIsOpen,
  hideModal,
  useRedux
}) {
  const [data, setData] = useState([]);
  const globalState = useSelector((state) => state.globalState);
  const dispatch = useDispatch();
  // const user = useSelector((state) => state.user);
  const getPlans = () => {
    Axios.get("/pitch/plans").then((res) => {
      setData(res.data.data);
    });
  };
  useEffect(() => {
    getPlans();
  }, []);
  const choosePlan = (plan) => {
    const { plan_name: planName } = plan;
    intiatePitchPurchase(planName);
  };
  if (useRedux && !globalState.showActivatePitchModal) return null;
  return (
    <ReactModal
      isOpen={useRedux ? globalState.showActivatePitchModal : modalIsOpen}
      className="postJob__completeBanner-modal postJob__completeBanner-modal-2"
      overlayClassName="postJob__completeBanner-overlay-2"
    >
      <div className={styles.container}>
        <div className={styles.title}>Activate Your Pitch</div>
        <div className={styles.plans}>
          {data.map((item) => (
            <div className={styles.plan} onClick={() => choosePlan(item)}>
              <div className={styles.planName}>{item.display_name}</div>
              <div className={styles.planRow}>
                <div className={styles.planLeft}>
                  <div className={styles.planDesc}>
                    Pitch to <span>{item.no_of_pitch_sends}</span> hirers
                  </div>
                </div>
                <div className={styles.planAmount}>
                  {" "}
                  {false ? `$${item.amountInUSD}` : `₹${item.amount}`}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className={styles.activateLaterMessage}
          onClick={
            useRedux ? () => dispatch(hideActivatePitchModal()) : hideModal
          }
        >
          Activate Later
        </div>
      </div>
    </ReactModal>
  );
}
