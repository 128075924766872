/* eslint-disable react/no-danger */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useMemo } from "react";
import sanitizeHtml from "sanitize-html";
import PropTypes from "prop-types";
import { useCustomMessageTimeHook } from "@/utils/customHooks";
import { formatChatDate } from "@/utils/dateTimeFunctions";
import ListCard from "@/pages/JobSeekerListsByType/ListCard/ListCard";
import Loader from "@/components/Loader";
import { captureEventForAnalytics } from "@/services/utilitiesService";
import styles from "./MessagesBox.module.scss";
import PitchCard from "./PitchCard";

const MessagesBox = React.forwardRef(
  (
    {
      messages,
      loggedInUserId,
      loggedInUserImg,
      chatWithUserImg,
      loading,
      lastMessageFetched,
      pfpSocial,
      name,
      extendedMode,
      chatWithUserId
    },
    ref
  ) => {
    const userContactClick = (type) => {
      captureEventForAnalytics({
        eventType: "chat_connect_click",
        eventData: {
          chatWithUserId,
          clickType: type,
          deviceType: "web"
        }
      });
    };
    return (
      <div
        className={`${styles.container} ${
          extendedMode ? styles.containerMax : ""
        }`}
      >
        {loading && !messages.length && (
          <div className={styles.loadingContainer}>
            <Loader />
          </div>
        )}
        <div ref={ref.lastMsg} style={{ float: "left", clear: "both" }} />
        {messages.map((message, index) => {
          const messageSent = message.fromUserId === loggedInUserId;
          let showDate = false;
          if (index === messages.length - 1) showDate = true;
          else if (
            new Date(message.crtd).toDateString() !==
            new Date(messages[index + 1].crtd).toDateString()
          )
            showDate = true;
          if (
            (message.type === "offer" || message.type === "applied_to_job") &&
            message.offerDetails
          ) {
            const cardMessage =
              message.type === "applied_to_job"
                ? messageSent
                  ? `${name} applied to your job.`
                  : `You applied to ${name}'s job.`
                : messageSent
                ? message.offerDetails.message
                    .replaceAll("Recruitor", "You")
                    .replaceAll("you", name)
                : message.offerDetails.message;
            return (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <ListCard
                    data={{
                      ...message.offerDetails,
                      message: cardMessage
                    }}
                    type="PENDING"
                    noTimeShown={
                      message.offerDetails.type === "OFFER" && messageSent
                    }
                    additionalClassName={styles.listCard}
                    noButtons

                    // grabOpportunity={grabOpportunity}
                    // acceptOffer={acceptOffer}
                    // messageHirer={messageHirer}
                    // rejectOffer={rejectOffer}
                    // onMessageClick={onMessageClick}
                    // deleteCard={() =>
                    //   setSelectedOffer({ id: item.id, type: item.type })
                    // }
                  />
                </div>
                {showDate ? <DateItem crtd={message.crtd} /> : ""}
              </>
            );
          }
          if (message.type === "PITCH" && message.pitchDetails)
            return <PitchCard data={message.pitchDetails} />;
          return messageSent ? (
            <MessageSent
              message={message.message}
              crtd={message.crtd}
              key={message._id}
              pfpSocial={pfpSocial}
              _id={message._id}
              showDate={showDate}
              userImg={loggedInUserImg}
              style={index === 0 ? { marginBottom: "-1rem" } : null}
            />
          ) : (
            <MessageReceived
              showDate={showDate}
              key={message._id}
              _id={message.crtd}
              message={message.message}
              userContactClick={userContactClick}
              crtd={message.crtd}
              userImg={chatWithUserImg}
              style={index === 0 ? { marginBottom: "-1rem" } : null}
            />
          );
        })}
        {!lastMessageFetched && (
          <div className={styles.loaderContainer} ref={ref.firstMsg}>
            <div
              style={
                loading && messages.length
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Loader />
            </div>
          </div>
        )}
      </div>
    );
  }
);
MessagesBox.propTypes = {
  loggedInUserId: PropTypes.number.isRequired,
  loggedInUserImg: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  lastMessageFetched: PropTypes.bool.isRequired,
  chatWithUserImg: PropTypes.string.isRequired,
  messages: PropTypes.array.isRequired,
  pfpSocial: PropTypes.string.isRequired
};

function urlify(originalText, onlyUrl, messageId) {
  let text = originalText;
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  text = text.replace(urlRegex, (url) => {
    let hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = `http://${hyperlink}`;
    }
    return `<a id="url_${messageId}" target="__blank" href="${url}">${url}</a>`;
  });
  if (onlyUrl) return { text, contactLinkFound: false };
  const emailRegex = /([a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4})/gi;

  text = text.replace(emailRegex, (email) => {
    console.log(email);
    return `<a id="email_${messageId}" target="__blank" href="mailto:${email}">${email}</a>`;
  });
  const phoneNumberRegex =
    /[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}/gi;
  text = text.replace(
    phoneNumberRegex,
    (number) =>
      `<a id="phone_${messageId}" target="__blank" href="tel:${number}">${number}</a>`
  );
  return {
    text,
    contactLinkFound:
      text.includes('href="tel') || text.includes('href="mailto')
  };
  // or alternatively
}

export default MessagesBox;
const MessageSent = React.memo(({ message, crtd, showDate, _id, style }) => {
  const relativeTime = useCustomMessageTimeHook(crtd);
  const messageHTML = useMemo(
    () => urlify(sanitizeHtml(message), true, _id),
    [message]
  );

  return (
    <>
      <div className={styles.messageSentContainer} style={style}>
        <div className={styles.messageSentBox}>
          <div
            className={styles.messageSentText}
            dangerouslySetInnerHTML={{
              __html: messageHTML.text
            }}
          />
          <div className={styles.messageSentTime}>{relativeTime.time}</div>
        </div>
        {/* <div className={styles.messageSentUserIcon}>
          <img src={pfpSocial} alt="" /> */}
        {/* </div> */}
      </div>
      {showDate ? <DateItem crtd={crtd} /> : ""}
    </>
  );
});
const MessageReceived = React.memo(
  ({ message, crtd, showDate, _id, userContactClick, style }) => {
    const relativeTime = useCustomMessageTimeHook(crtd);
    const messageHTML = useMemo(
      () => urlify(sanitizeHtml(message), false, _id),
      [message]
    );

    useEffect(() => {
      let emailEl;
      let phoneEl;
      if (messageHTML.contactLinkFound) {
        emailEl = document.getElementById(`email_${_id}`);
        if (emailEl)
          emailEl.addEventListener("click", () => {
            userContactClick("EMAIL");
          });
        phoneEl = document.getElementById(`phone_${_id}`);
        if (phoneEl)
          phoneEl.addEventListener("click", () => {
            userContactClick("MOBILE_NUMBER");
          });
      }
      return () => {
        if (messageHTML.contactLinkFound) {
          if (emailEl)
            emailEl.removeEventListener("click", () => {
              userContactClick("EMAIL");
            });
          if (phoneEl)
            phoneEl.removeEventListener("click", () => {
              userContactClick("MOBILE_NUMBER");
            });
        }
      };
    }, [messageHTML.contactLinkFound]);
    return (
      <>
        <div className={styles.messageReceivedContainer} style={style}>
          {/* <div className={styles.messageReceivedUserIcon}>
          <img src={userImg} alt="" />
        </div> */}
          <div className={styles.messageReceivedBox}>
            <div
              className={styles.messageReceivedText}
              dangerouslySetInnerHTML={{ __html: messageHTML.text }}
            />
            <div className={styles.messageReceivedTime}>
              {relativeTime.time}
            </div>
          </div>
        </div>
        {showDate ? <DateItem crtd={crtd} /> : ""}
      </>
    );
  }
);
const DateItem = ({ crtd }) => (
  <div className={styles.chatDateContainer}>
    {/* <div className={styles.chatDateLine} /> */}
    <div className={styles.chatDateValue}>{formatChatDate(new Date(crtd))}</div>
  </div>
);

DateItem.propTypes = {
  crtd: PropTypes.any.isRequired
};
MessageSent.propTypes = {
  message: PropTypes.string.isRequired,
  crtd: PropTypes.any.isRequired,
  showDate: PropTypes.bool.isRequired
};
MessageReceived.propTypes = {
  message: PropTypes.string.isRequired,
  crtd: PropTypes.any.isRequired,
  showDate: PropTypes.bool.isRequired
};
