import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import sanitizeHtml from "sanitize-html";
import { useParams, useHistory } from "react-router-dom";
import ReactModal from "react-modal";
import {
  getProfileDetailsApi,
  getResumeParsingStatusApi
} from "@/services/userProfileService";
import apple from "@/assets/svg/app-store.svg";
import playstore from "@/assets/svg/gplay.svg";
import {
  fetchParticularOfferApi,
  applyToJobOfferApi
} from "@/services/jobOfferService";
// import ResumeUploader from "@/components/ResumeUploader/ResumeUploader";
// import logo from "@/assets/logo/logoComplete.png";
import FullLogo from "@/components/FullLogo/FullLogo";
import NotLoggedInModal from "@/components/NotLoggedInModal";
import { showToastMessage } from "@/components/ToastComponent";
import { ResumeUploaderJobApplyModal } from "@/components/ResumeUploader";
import JobInterviewModal from "@/components/JobInterviewModal";
import { setUserAsActive } from "@/redux/actions/userActions";
import styles from "./UploadResumeBeforeApplyingToJobPage.module.scss";
import successAnim from "./applied.json";
import LoadingAnimation from "./animation.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
function urlify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, (url) => {
    let hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = `http://${hyperlink}`;
    }
    return `<a  target="__blank" href="${url}">${url}</a>`;
  });
  // or alternatively
}
export default function UploadResumeBeforeApplyingToJobPage() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const interval = React.useRef(null);
  const [interviewLink, setInterviewLink] = useState(null);
  const [applied, setApplied] = useState(false);
  const [fetchingFromResume, setFetchingFromResume] = useState(false);
  const userInfo = useSelector((state) => state.user);
  const [showUploadResumeModal, setShowUploadResumeModal] = useState(false);
  const [jobData, setJobData] = useState(null);
  const openAppstore = () => {
    window.open("https://apps.apple.com/in/app/trustze/id1495314369", "_blank");
  };
  const openGooglePlay = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.trustze",
      "_blank"
    );
  };
  const { offerId } = useParams();
  const history = useHistory();
  const uploaderRef = React.useRef(null);
  const applyToJob = () => {
    if (jobData.userId === userInfo.user_id) {
      showToastMessage("error", "You cannot apply to your own job.");
    } else if (!jobData.hasApplied) {
      console.log("applying");
      applyToJobOfferApi(jobData.offerId)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data.isGoodFit) {
              setInterviewLink(res.data.data.interviewLink);
              // setShowJobPreScreenModal(false);
            }
            setApplied(true);
            showToastMessage("info", "Applied successfully!");
            // window.open(
            //   `https://gpt3.ourgoodspace.com/${jobData.offerId}`,
            //   "_blank"
            // );
          } else {
            showToastMessage("error", res.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
          if (
            err?.response?.data?.data &&
            err.response.data.data.error === "RESUME_NOT_FOUND"
          )
            setShowUploadResumeModal(true);
        })
        .finally(() => {
          setFetchingFromResume(false);
        });
    } else {
      console.log("here 2");
      showToastMessage("error", "You have already applied to this Job.");
    }
  };
  // const applyToJob = () => {

  //   if (!applied) {
  //     console.log("applying");
  //     applyToJobOfferApi(offerId)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           showToastMessage("info", "Applied successfully!");

  //           setApplied(true);
  //         } else {
  //           showToastMessage("error", res.data.message);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         if (
  //           err?.response?.data?.data &&
  //           err.response.data.data.error === "RESUME_NOT_FOUND"
  //         )
  //           setShowUploadResumeModal(true);
  //       });
  //   } else {
  //     console.log("here 2");
  //     showToastMessage("error", "You have already applied to this Job.");
  //   }
  // };

  const getData = () => {
    setLoading(true);
    fetchParticularOfferApi(offerId)
      .then((res) => {
        setJobData(res.data.data[0]);
      })
      .catch(() => {
        history.push("/");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const dispatch = useDispatch();
  const getResumeParsingStatus = () => {
    getResumeParsingStatusApi("true").then((res) => {
      if (res.data.data.processStatus === "IN_PROGRESS") {
        setFetchingFromResume(true);
      } else if (res.data.data.processStatus === "COMPLETED") {
        clearInterval(interval.current);
        if (res.data.data.isResumeParsed) {
          dispatch(setUserAsActive("JOB_SEEKER"));
          getProfileDetailsApi(userInfo.user_id, true).then((res2) => {
            console.log(res2.data.data);
            const { workExperienceRange } = res2.data.data;
            if (workExperienceRange) {
              let eventName = "JobSeeker_";
              const { lowerworkex = 0, upperworkex = 1 } = workExperienceRange;
              if (lowerworkex === 0 && upperworkex === 1)
                eventName += "Fresher";
              else if (lowerworkex < 30)
                eventName += `${lowerworkex}_${upperworkex}_Yrs`;
              else if (lowerworkex === 30) eventName += "more_than_30_Yrs";
              window.fbq("trackCustom", eventName, {
                name: userInfo.name,
                userId: userInfo.user_id
              });
            }
          });
          applyToJob();
          // updateOnboardingDataState({
          //   resumeAddedByJobSeeker: true,
          //   name: res.data.data.userDetails?.name,
          //   emailId: res.data.data.userDetails?.emailId,
          //   errorInUserDetails: res.data.data.userDetails?.errorInUserDetails,
          //   userDetailsFound: res.data.data.userDetails?.userDetailsFound
          // });
          // setUserDetailsFromResume(res.data.data.userDetails);
          // setResumeUploaded(true);
        } else {
          setShowUploadResumeModal(true);
          showToastMessage(
            "error",
            "We were unable to parse your resume. Please upload a new resume."
          );
        }
      } else {
        clearInterval(interval.current);
        setFetchingFromResume(false);
      }
    });
  };
  useEffect(() => {
    if (fetchingFromResume) {
      getResumeParsingStatus();
      interval.current = setInterval(getResumeParsingStatus, 1500);
    }
    return () => clearInterval(interval.current);
  }, [fetchingFromResume]);
  if (loading) return <div className={styles.page}>Loading...</div>;
  return (
    <div className={styles.page}>
      {/* <Navbar /> */}
      <div className={styles.logo}>
        <FullLogo />
      </div>
      {applied ? (
        <div className={styles.container}>
          <div className={styles.text}>
            <div className={styles.animation2}>
              <Lottie
                options={{
                  ...defaultOptions,
                  animationData: successAnim
                }}
                isClickToPauseDisabled
              />
            </div>
            <div className={styles.successText}>
              Thank you for taking the time to
              <br />
              apply for this position.
            </div>
            <div className={styles.successSubText}>
              We will review your application and get in touch with you soon. We
              wish you the best of luck in your job search at GoodSpace.
            </div>
            <div className={styles.linksContainer}>
              <div className={`${styles.subheader} ${styles.linksText}`}>
                Download the GoodSpace app to receive instant alerts and
                notifications.
              </div>
              <div className={styles.btnContainer}>
                <img
                  src={apple}
                  alt=""
                  className={styles.btn}
                  onClick={openAppstore}
                />
                <img
                  src={playstore}
                  alt=""
                  className={styles.btn}
                  onClick={openGooglePlay}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.jobTitle}>{jobData?.title}</div>
          <div className={styles.jobHeading}>
            {jobData?.location_city || "Remote"}
          </div>
          <div className={styles.jobDetailCards}>
            <div className={styles.jobDetailCard}>
              <ExpIcon /> {jobData?.lowerworkex}-{jobData?.upperworkex} Years
            </div>
            <div className={styles.jobDetailCard}>
              <CompIcon /> {jobData?.displayCompensation}
            </div>
            {!!jobData?.jobType?.length || jobData?.type_of_service ? (
              <div className={styles.jobDetailCard}>
                {!jobData?.type_of_service && <JobTypeIcon />}
                {jobData?.jobType?.length
                  ? jobData?.jobType[0].job_type
                  : jobData?.type_of_service}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className={styles.jobSubHeading}>Posted By</div>
          <div className={styles.jobPostedBy}>
            <div className={styles.jobPostedByImage}>
              <img src={jobData?.userInfo.image_id} alt="company logo" />
            </div>

            <div className={styles.jobPostedByText}>
              <div className={styles.jobPostedByName}>
                {jobData?.userInfo.name}
              </div>
              <div className={styles.jobPostedByOrg}>
                {jobData?.companyName}
              </div>
            </div>
          </div>
          {!jobData?.hasApplied && jobData?.status !== "INACTIVE" ? (
            userInfo.token ? (
              <div
                className={styles.applyButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowUploadResumeModal(true);
                  // setShowJobPreScreenModal(true);
                }}
              >
                Apply
              </div>
            ) : (
              <div
                className={styles.applyButton}
                onClick={() => setShowLoginModal(true)}
              >
                Apply
              </div>
            )
          ) : (
            <div className={styles.applyButton}>
              {jobData?.status === "INACTIVE" ? "Job Closed" : "Applied "}
            </div>
          )}
          <div className={styles.jobHeading}>Preferred Skills</div>
          <div className={styles.jobDetailCards}>
            {jobData?.skills.map((skill) => (
              <div className={styles.jobDetailCard}>{skill.skill}</div>
            ))}
          </div>

          <div className={styles.jobSubHeading}>Job Description</div>
          <div
            className={styles.jobDescription}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(urlify(jobData?.description || ""))
            }}
          />
        </div>
      )}
      <ReactModal
        isOpen={fetchingFromResume}
        className="postJob__completeBanner-modal"
        overlayClassName="postJob__completeBanner-overlay-2"
      >
        <div className={styles.modalContainer}>
          Please wait while we are extracting details from your resume
          <div className={styles.modalAnim}>
            <Lottie
              options={{
                ...defaultOptions,
                animationData: LoadingAnimation
              }}
              isClickToPauseDisabled
            />
          </div>
        </div>
      </ReactModal>

      <JobInterviewModal
        isOpen={!!interviewLink}
        interviewLink={interviewLink}
        hideModal={() => setInterviewLink(null)}
        interviewLinkLocal={interviewLink}
      />
      <NotLoggedInModal
        isOpen={showLoginModal}
        doNotRedirect
        hideModal={() => {
          setShowLoginModal(false);
        }}
        onLoginComplete={() => {
          setShowUploadResumeModal(true);
          // setShowJobPreScreenModal(true);
          // uploaderRef.current?.getFileUploader()?.click();
          // localStorage.removeItem("token");
        }}
      />
      {showUploadResumeModal && (
        <ResumeUploaderJobApplyModal
          isOpen={showUploadResumeModal}
          onUploadSuccess={() => {
            setFetchingFromResume(true);
            setShowUploadResumeModal(false);
          }}
          onRequestClose={() => setShowUploadResumeModal(false)}
          applyToJob={applyToJob}
        />
      )}
      {/* {showJobPreScreenModal && (
        <JobApplyRequirementModal
          isOpen={showJobPreScreenModal}
          hideModal={(e) => {
            e.stopPropagation();
            setShowJobPreScreenModal(false);
          }}
          applied={jobData.hasApplied}
          onFindMoreJobs={(e) => {
            e.stopPropagation();
            history.push("/dashboard");
          }}
          onApply={applyToJob}
          offerId={jobData.offerId}
          offerDetails={{
            jobTitle: jobData.title,
            location: jobData.location_city || "Remote",
            compensation: jobData.displayCompensation,
            experience: `${jobData.lowerworkex}-${jobData.upperworkex}`,
            skills: jobData.skills.map((a) => a.skill)
          }}
        />
      )} */}
    </div>
  );
}

const ExpIcon = () => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8462 2.33333H10.961V1.75C10.9604 1.28603 10.7779 0.841214 10.4535 0.513139C10.129 0.185063 9.68906 0.000521086 9.23019 0H5.76865C5.30978 0.000521086 4.86985 0.185063 4.54538 0.513139C4.22091 0.841214 4.0384 1.28603 4.03788 1.75V2.33333H1.15385C0.847933 2.33368 0.554647 2.45671 0.338333 2.67543C0.122019 2.89414 0.000343573 3.19069 0 3.5V12.8333C0.000343573 13.1426 0.122019 13.4392 0.338333 13.6579C0.554647 13.8766 0.847933 13.9997 1.15385 14H13.8462C14.1521 13.9997 14.4454 13.8766 14.6617 13.6579C14.878 13.4392 14.9997 13.1426 15 12.8333V3.5C14.9997 3.19069 14.878 2.89414 14.6617 2.67543C14.4454 2.45671 14.1521 2.33368 13.8462 2.33333ZM5.19173 1.75C5.19192 1.59535 5.25277 1.44709 5.36092 1.33773C5.46907 1.22838 5.6157 1.16686 5.76865 1.16667H9.23019C9.38314 1.16686 9.52978 1.22838 9.63793 1.33773C9.74608 1.44709 9.80692 1.59535 9.80712 1.75V2.33333H5.19173V1.75ZM9.80712 3.5V12.8333H5.19173V3.5H9.80712ZM1.15385 3.5H4.03788V12.8333H1.15385V3.5ZM13.8462 12.8333H10.961V3.5H13.8462L13.8469 12.8333H13.8462Z"
      fill="black"
    />
    <path
      d="M9.81111 3.49316H10.9903V12.823H9.77344L9.81111 3.49316Z"
      fill="#F2F9FF"
    />
    <path
      d="M4.00684 3.49316H5.36653V12.823H3.93359L4.00684 3.49316Z"
      fill="#F2F9FF"
    />
  </svg>
);
const CompIcon = () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.6"
      y="0.6"
      width="15.8"
      height="12.8"
      rx="1.1"
      stroke="black"
      strokeWidth="1.2"
    />
    <path
      d="M10.9658 5.62891L10.7412 6.40039H6.20996L6.43457 5.62891H10.9658ZM8.93457 11L6.35156 7.98242L6.34668 7.37695H7.53809C7.88314 7.37695 8.16797 7.31999 8.39258 7.20605C8.62044 7.08887 8.79134 6.93099 8.90527 6.73242C9.01921 6.53385 9.07617 6.31087 9.07617 6.06348C9.07617 5.78678 9.02246 5.54427 8.91504 5.33594C8.80762 5.12435 8.63835 4.95996 8.40723 4.84277C8.17936 4.72233 7.87988 4.66211 7.50879 4.66211H6.21973L6.44922 3.89062H7.50879C8.0752 3.89062 8.54232 3.97689 8.91016 4.14941C9.28125 4.31868 9.55794 4.56608 9.74023 4.8916C9.92253 5.21712 10.0137 5.611 10.0137 6.07324C10.0137 6.47038 9.93717 6.82682 9.78418 7.14258C9.63444 7.45508 9.38542 7.70085 9.03711 7.87988C8.69206 8.05892 8.22656 8.14844 7.64062 8.14844L10.043 10.9414V11H8.93457ZM10.9658 3.89062L10.7412 4.66211H7.08887L7.31348 3.89062H10.9658Z"
      fill="black"
    />
  </svg>
);
const JobTypeIcon = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3407 0H8.86077C8.34653 0 7.92578 0.374969 7.92578 0.833265V2.17482L7.93513 2.18316L12.6101 5.93285C13.1991 6.39948 13.5357 7.08276 13.5357 7.79103V8.33265H15.4057V9.99918H13.5357V11.6657H15.4057V13.3322H13.5357V14.9988H16.3407C16.8549 14.9988 17.2757 14.6238 17.2757 14.1655V0.833265C17.2757 0.374969 16.8549 0 16.3407 0ZM11.6657 3.33306H9.79576V1.66653H11.6657V3.33306ZM15.4057 6.66612H13.5357V4.99959H15.4057V6.66612ZM15.4057 3.33306H13.5357V1.66653H15.4057V3.33306Z"
      fill="black"
    />
    <path
      d="M11.4288 14.1668V7.79553C11.4285 7.67932 11.4026 7.56444 11.3528 7.4583C11.303 7.35216 11.2303 7.2571 11.1395 7.17923L6.75678 3.43145C6.59791 3.28975 6.38738 3.21212 6.16949 3.21492C5.95912 3.21492 5.74875 3.28987 5.58221 3.43145L1.19947 7.17923C1.10735 7.25607 1.0338 7.35094 0.983871 7.45731C0.933944 7.56369 0.908816 7.67907 0.910211 7.79553V14.1668C0.910211 14.6248 1.30466 14.9996 1.78676 14.9996H3.53985C4.02195 14.9996 4.4164 14.6248 4.4164 14.1668V10.8354H7.92259V14.1668C7.92259 14.6248 8.31703 14.9996 8.79914 14.9996H10.5522C11.0343 14.9996 11.4288 14.6248 11.4288 14.1668Z"
      fill="black"
    />
  </svg>
);
