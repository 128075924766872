import React, { useEffect, useState } from "react";
import NotificationCard from "./NotificationCard";

function NotificationHelper(data) {
  const [obj, setObj] = useState([]);
  useEffect(() => {
    setObj((prevobj) => [...new Set([...prevobj, ...data.data])]);
  }, [data]);
  return (
    <>
      {obj.map((i) => (
        <NotificationCard data={i} key={i.userNotificationId} />
      ))}
    </>
  );
}
export default NotificationHelper;
