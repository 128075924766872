/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import FullLogo from "@/components/FullLogo/FullLogo";
import { NavLink, useLocation } from "react-router-dom";
import SupportModal from "@/components/SupportModal";
import { ProgressBar } from "@/pages/EditHirerProfilePage/EditHirerProfilePage";
import {
  DashboardIcon,
  FeedbackIcon,
  NavLinkIconWithAlert,
  PostJobIcon,
  ProfileIcon,
  HiringIcon,
  LogoIcon,
  RightArrow,
  NotifIcon,
  LogOutIcon,
  JobIcon,
  CandiIcon,
  SupportIcon,
} from "./SideNavIcons";

export default function SideNav({
  startNavbarSlideAnimate,
  setOpenDropdown,
  setStartNavbarSlideAnimate,
  messageCount,
  changeAppMode,
  userMode,
  logout,
}) {
  const location = useLocation();


  // support modal
  const [showSupportModal, setShowSupportModal] = useState(false);
  const handleClose = () => {
    setShowSupportModal(false);
  };

  return (
    <div
      className={`dashboardNavbar__sideNav-container ${startNavbarSlideAnimate ? "dashboardNavbar__sideNav-container-hide" : ""
        }`}
      onTransitionEnd={() => {
        setTimeout(() => {
          setOpenDropdown(false);
          setStartNavbarSlideAnimate(false);
        }, [100]);
      }}
    >

      <div className="dashboardNavbar__sideNav-links">

        {/* <NavLink
          exact
          to={`${
            userMode === "HIRING"
              ? "/hirer"
              : userMode === "SOCIAL"
              ? "/social"
              : "/member"
          }/dashboard`}
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <DashboardIcon active={location.pathname.includes("/dashboard")} />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Dashboard
          </div>
        </NavLink> */}


        {/* <div
          className="dashboardNavbar__sideNav-links-link"
          onClick={changeAppMode}
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <SwitchModeIcon />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Switch Mode
          </div>
        </div> */}



        <NavLink
          exact
          to={userMode !== "HIRING" ? "/profile" : "/hirer/profile"}
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <ProfileIcon active={location.pathname === "/hirer/profile"} />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Profile
          </div>
        </NavLink>


        {userMode === "HIRING" && (
          <ProgressBar showProgress />
        )}

        {userMode === "HIRING" && (
          <NavLink
            exact
            to="/hirer/dashboard"
            activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
          >
            <div className="dashboardNavbar__sideNav-links-link-icon">
              <JobIcon active={location.pathname === "/hirer/dashboard"} />
            </div>
            <div className="dashboardNavbar__sideNav-links-link-text">
              Jobs
            </div>
          </NavLink>

        )}

        {userMode === "HIRING" && (
          <NavLink
            exact
            to="/hire"
            activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
          >
            <div className="dashboardNavbar__sideNav-links-link-icon">
              <CandiIcon active={location.pathname === "/hire"} />
            </div>
            <div className="dashboardNavbar__sideNav-links-link-text">
              Candidates
            </div>
          </NavLink>
        )}
        {userMode === "HIRING" && (
          <NavLink
            exact
            to="/create-job-post"
            activeClassName="dashboardNavbar__sideNav-links-link-active"
            className="dashboardNavbar__sideNav-links-link"
          >
            <div className="dashboardNavbar__sideNav-links-link-icon">
              <PostJobIcon active={location.pathname === "/create-job-post"} />
            </div>
            <div className="dashboardNavbar__sideNav-links-link-text">
              Post a Job
            </div>
          </NavLink>
        )}

        <NavLink
          exact
          to="/chat"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <NavLinkIconWithAlert
              active={location.pathname === "/chat"}
              notifCount={messageCount}
            />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Message
          </div>
        </NavLink>

        <NavLink
          exact
          to="/notifications"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
          onClick={(e) => {
            e.preventDefault();
            setShowSupportModal(true);
          }}
        >
          <div className="dashboardNavbar__sideNav-links-link-icon" style={{ display: 'flex', justifyContent: 'center' }}>
            <SupportIcon />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Need Support
          </div>
        </NavLink>

        <NavLink
          exact
          to="/notifications"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <NotifIcon
              active={location.pathname === "/notifications"}
            />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Notification
          </div>
        </NavLink>

        {/* <NavLink
          exact
          to="/feedback"
          activeClassName="dashboardNavbar__sideNav-links-link-active"
          className="dashboardNavbar__sideNav-links-link"
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <FeedbackIcon active={location.pathname === "/feedback"} />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Feedback
          </div>
        </NavLink> */}

        {userMode !== "HIRING" && (
          <div
            className="dashboardNavbar__sideNav-links-link"
            onClick={() => changeAppMode("HIRING")}
          >
            <div className="dashboardNavbar__sideNav-links-link-icon">
              <HiringIcon />
            </div>
            <div className="dashboardNavbar__sideNav-links-link-text">
              Hire Talent
            </div>
          </div>
        )}


        <div
          className="dashboardNavbar__sideNav-links-link"
          onClick={logout}
        >
          <div className="dashboardNavbar__sideNav-links-link-icon">
            <LogOutIcon />
          </div>
          <div className="dashboardNavbar__sideNav-links-link-text">
            Log out
          </div>
        </div>
      </div>


      {/* <button className="dashboardNavbar__sideNav-logout" onClick={logout}>
        Log out <RightArrow />
      </button> */}

      <div className="dashboardNavbar__sideNav-logo">
        <LogoIcon />
      </div>

      <SupportModal showSupportModal={showSupportModal} handleClose={handleClose} prependText="DASHBOARD:" />
    </div>
  );
}
