import store from "@/redux/store";
import { updateUserDetails, login, logout } from "@/redux/actions/userActions";
import { setOnboardingStatus } from "@/redux/actions/globalStateActions";
import { setNotificationCountData } from "@/redux/actions/notificationActions";
import { mainAxiosInstance } from "./networkAdapters/axiosInstance";
import {
  getProfileDetailsApi,
  saveOnboardingStepApi,
  getProfileForHirer,
  getProfileDetailsAlternateApi
} from "./userProfileService";
import { getNotificationData } from "./notificationService";

export function signUpApi(data) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post("/auth/signup", data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in signUpApi", e.response);
        reject(e);
      });
  });
}

export function loginApi(number, countryCode, reCaptchaToken, recaptchaAction) {
  return new Promise((resolve, reject) => {
    if (!number || !countryCode) {
      reject(new Error("Number cant be empty"));
      return;
    }
    mainAxiosInstance
      .post("/auth/v2/login", {
        number,
        countryCode,
        reCaptchaToken,
        recaptchaAction
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in loginApi", e.response);
        reject(e);
      });
  });
}
export function checkReferralCodeApi(referralCode) {
  return new Promise((resolve, reject) => {
    if (!referralCode) {
      reject(new Error("ReferralCode cant be empty"));
      return;
    }
    mainAxiosInstance
      .post("/auth/check_referral_code", {
        referralCode
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.error("Error in checkReferralCodeApi", e.response);
        reject(e);
      });
  });
}

export function resendOtpApi(number, countryCode) {
  return new Promise((resolve, reject) => {
    mainAxiosInstance
      .post("/auth/resendOtp", {
        number,
        countryCode
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("Error in resendOtpApi", e.response);
        reject(e);
      });
  });
}

export function verifyOtpApi(
  number,
  otp,
  userModeToChange,
  needToChangeUserMode
) {
  return new Promise((resolve, reject) => {
    const inviteUserId = window.localStorage.getItem("inviteUserId");
    const utmTrackingData = window.localStorage.getItem("utmTrackingData");
    mainAxiosInstance
      .post("/auth/verifyotp", {
        number,
        otp,
        inviteId: inviteUserId || null,
        utmTracking: utmTrackingData ? JSON.parse(utmTrackingData) : null
      })
      .then(async (res) => {
        if (res.status === 201 || res.status === 200) {
          mainAxiosInstance.defaults.headers["X-CSRF-Token"] =
            res.data.data.token;
          window.localStorage.setItem("token", res.data.data.token);
          if (
            (res.data.data.userMode === "SOCIAL_SERVICES" &&
              res.data.data.status === 2) ||
            res.data.data.status === 3
          ) {
            if (
              res.data.data.needToDoOnboarding &&
              res.data.data.status === 3
            ) {
              store.dispatch(setOnboardingStatus(res.data.data.userMode));
            }
            const refreshDetailsPayload={
              token:res.data.data.token,
              user_id:res.data.data.user_id,
              country_code:res.data.data.country_code,
              mobile_number:res.data.data.mobile_number,
              dob:res.data.data.dob,
              image_id:res.data.data.image_id,
              location_city:res.data.data.location_city,
              name:res.data.data.name,
              email_id:res.data.data.email_id,
              status:res.data.data.status,
              invite_link:res.data.data.invite_link,
              userMode:res.data.data.userMode,
              needToDoOnboarding:res.data.data.needToDoOnboarding,
              previousUserMode:res.data.data.previousUserMode,
              
            }
            store.dispatch(login(refreshDetailsPayload));
            if(res.data.data.userMode==="HIRING"){
              await getProfileForHirer(res.data.data.user_id, true);
            }
            else if(res.data.data.userMode==="JOB_SEEKER"){
              // await getProfileDetailsApi(res.data.data.user_id, true);
              await getProfileDetailsAlternateApi(res.data.data.user_id);
            }
            else if(res.data.data.userMode==="SOCIAL"){
  
            }
            else{
              store.dispatch(logout());
              localStorage.clear();
              console.log("Mode Not Correct", res.data.data.mode);
            }
            // store.dispatch(
            //   setNotificationCountData({
            //     newCandidateApplyCount: res2.data.data.newCandidateApplyCount,
            //     newOfferReceivedCount: res2.data.data.newOfferReceivedCount
            //   })
            // );
            getNotificationData();
          } else {
            if (userModeToChange && needToChangeUserMode) {
              await saveOnboardingStepApi("purpose", {
                userMode: userModeToChange
              });
            }
            store.dispatch(login(res.data.data));
          }
          console.log("setting identity");

          resolve(res);
        }
      })
      .catch((e) => {
        console.log(e);
        console.error("Error in verifyOtpApi", e);
        reject(e);
      });
  });
}

export function fetchUserBasicInfoApi(token, earlyReturn) {
  return new Promise((resolve) => {
    mainAxiosInstance
      .get("/profile/refreshDetails")
      .then(async (res) => {
        const data = res.data.data[0];
        if (res.status === 201 || res.status === 200) {
          if (data.status === 2 && data.userMode !== "SOCIAL_SERVICES") {
            
            window.localStorage.setItem("token", token);
            mainAxiosInstance.defaults.headers["X-CSRF-Token"] = token;
            // return;
          }
          if (earlyReturn) {
            resolve(res.data.data);
            return;
          }
          window.localStorage.setItem("token", token);
          mainAxiosInstance.defaults.headers["X-CSRF-Token"] = token;
          if (data.needToDoOnboarding && data.status === 3) {
            store.dispatch(setOnboardingStatus(data.userMode));
          }
          const refreshDetailsPayload={
            token,
            user_id:data.user_id,
            country_code:data.country_code,
            mobile_number:data.mobile_number,
            dob:data.dob,
            image_id:data.image_id,
            location_city:data.location_city,
            name:data.name,
            email_id:data.email_id,
            status:data.status,
            invite_link:data.invite_link,
            userMode:data.userMode,
            needToDoOnboarding:data.needToDoOnboarding,
            previousUserMode:data.previousUserMode,
          }
          store.dispatch(login(refreshDetailsPayload));
          if(data.userMode==="HIRING"){
            await getProfileForHirer(data.user_id, true);
          }
          else if(data.userMode==="JOB_SEEKER"){
            await getProfileDetailsAlternateApi(data.user_id);
          }
          else if(data.userMode==="SOCIAL"){

          }
          // else{
          //   store.dispatch(logout());
          //   localStorage.clear();
          //   console.log("Mode Not Correct", data.mode);
          // }

          // store.dispatch(
          //   setNotificationCountData({
          //     newCandidateApplyCount: res2.data.data.newCandidateApplyCount,
          //     newOfferReceivedCount: res2.data.data.newOfferReceivedCount
          //   })
          // );

          // getNotificationData();
        }
        resolve(res);
      })
      .catch((e) => {
        store.dispatch(logout());
        localStorage.clear();
        console.log(e);
        console.log("Error in fetchUserBasicInfo", e.response);
        resolve(e);
      });
  });
}
