import {
  LOGIN,
  LOGOUT,
  SET_USER_AS_ACTIVE,
  UPDATE_PFP,
  UPDATE_USER_DETAILS,
} from "../CONSTANTS";

export const login = (userData, mode) => ({
  type: LOGIN,
  payload: userData,
  mode,
});
export const logout = () => ({ type: LOGOUT });
export const setUserAsActive = (userMode = "JOB_SEEKER") => ({
  type: SET_USER_AS_ACTIVE,
  payload: userMode,
});
export const updatePfp = (data) => ({ type: UPDATE_PFP, payload: data });
export const updateUserDetails = (data,mode) => ({
  type: UPDATE_USER_DETAILS,
  payload: data,
  mode,
});
