// eslint-disable-next-line import/prefer-default-export
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthsFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const addZero = (val) => (!val ? "00" : val < 10 ? `0${val}` : `${val}`);
export const getTimeIn12Hours = (date) => {
  const hour = date.getHours();
  return `${addZero(hour > 12 ? hour % 12 : hour)}:${addZero(
    date.getMinutes()
  )} ${hour > 12 ? "PM" : "AM"}`;
};
export const formatDate = (date, variation = 1) =>
  `${addZero(date.getDate())}${variation === 1 ? "/" : "-"}${addZero(
    date.getMonth() + 1
  )}${variation === 1 ? "/" : "-"}${addZero(date.getFullYear())}`;

export const formatDate2 = (date) =>
  `${months[date.getMonth()]} ${date.getDate()}${
    date.getFullYear() !== new Date().getFullYear()
      ? `, ${date.getFullYear()}`
      : ""
  }`;

export const formatTime = (time) => {
  const date = new Date(time);
  const yesterday = new Date();
  yesterday.setDate(new Date().getDate() - 1);

  if (date.toDateString() === new Date().toDateString()) {
    return getTimeIn12Hours(date);
  }
  if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  }
  return formatDate(date);
};
export const formatTime2 = (time) => {
  const date = new Date(time);
  const yesterday = new Date();
  yesterday.setDate(new Date().getDate() - 1);

  if (date.toDateString() === new Date().toDateString()) {
    return getTimeIn12Hours(date);
  }
  // if (date.toDateString() === yesterday.toDateString()) {
  //   return "Yesterday";
  // }
  return formatDate2(date);
};
export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  interval = seconds / 2592000;
  interval = seconds / 86400;
  interval = seconds / 3600;
  interval = seconds / 60;
  if (interval > 16) {
    return { thresholdCompleted: true, time: getTimeIn12Hours(date) };
  }
  if (interval > 1) {
    return {
      thresholdCompleted: false,
      time: `${Math.floor(interval)} min ago`,
    };
  }
  return { thresholdCompleted: false, time: "Just now" };
};
export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
}

export function getDiffInMonths(startMonth, startYear, endMonth, endYear) {
  const d1 = new Date(startYear, startMonth - 1, 1);
  const d2 = new Date(endYear, endMonth - 1, 1);
  let months_;
  months_ = (d2.getFullYear() - d1.getFullYear()) * 12;
  months_ -= d1.getMonth();
  months_ += d2.getMonth();
  return months_ <= 0 ? 0 : months_;
}
export const formatChatDate = (date) =>
  `${date.getDate()} ${monthsFull[date.getMonth()]} ${date.getFullYear()}`;
